import { Spin } from "antd"
import CenterContent from "./styled/CenterContent.styled"

const Spinner = () => {
  return (
    <CenterContent>
      <Spin />
    </CenterContent>
  )
}

export default Spinner
