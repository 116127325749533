import styled from "styled-components"

const SignInHeroContainer = styled.div`
  position: relative;
  img {
    width: 100%;
    object-fit: cover;
    max-height: 30vh;
  }

  & > div {
    text-align: center;
    background-color: hsla(0, 100%, 0%, 0.6);
    padding: 3rem;
    color: #fff;
    min-width: 100%;

    @media screen and (min-width: 768px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 45%;
    }

    @media screen and (min-width: 992px) {
      max-width: 55%;
    }
  }
`

const Muted = styled.span`
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  font-size: 2rem;
`

const Heading = styled.h1`
  text-transform: uppercase;
  font-weight: 800;
  font-size: 3rem;
  text-align: center;
`


const ProgramButton = styled.button`

width: 100%;
background-color: ${({ theme }) => theme.colors.primaryColor} !important;
color: #fff !important;
border-color: ${({ theme }) => theme.colors.primaryColor};
border-radius: 0;
&:hover {
  background-color: ${({ theme }) => theme.colors.secondaryColor} !important;
}

&:focus {
  background-color: #ccc;
  border-color: #ccc;
}

&:disabled {
  background-color: #ccc;
  color: #888;
  border: none;
}
`
const ProgramTile = styled.div`
  border: 1px solid #ccc;
  height: 100%;
`
const ProgramDetails = styled.div`
  font-weight: bold;
  font-size: 1rem;
  border-top: 1px solid #ccc;
  padding: .5rem 1.5rem;
  margin-bottom: .5rem;

  @media screen and (max-width: 500px)
  {
    display: none;
  }

  div {
    padding-top: .5rem;
    font-size: small;
    font-weight: normal;
  }
`

export { Heading, Muted, ProgramButton, ProgramTile, ProgramDetails, SignInHeroContainer }