enum IClaimStatus {
  PENDING = "Pending",
  APPROVED = "Approved",
  DECLINED = "Declined",
  FUNDED = "Funded",
}

const ClaimStatusColor = {
  [IClaimStatus.PENDING]: "warning",
  [IClaimStatus.DECLINED]: "error",
  [IClaimStatus.APPROVED]: "processing",
  [IClaimStatus.FUNDED]: "success",
}

const claimStatusIterable = [
  { label: "All", value: "All" },
  { label: "Approved", value: "Approved" },
  { label: "Declined", value: "Declined" },
  { label: "Pending", value: "Pending" },
  { label: "Funded", value: "Funded" },
]

export { ClaimStatusColor, IClaimStatus, claimStatusIterable }
