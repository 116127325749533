import type { ColumnsType } from "antd/es/table"
import type { pages, users } from "../../types/datatable"
import { Heading } from "../../components/styled/Heading"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  GET_USERS_WITH_PAGINATION,
  GET_USER_ROLES,
  RESEND_EMAIL_TO_USER,
} from "../../graphql/queries"
import { useEffect, useState } from "react"
import { Alert, Form, Input, Popconfirm, Select } from "antd"
import DataTable from "../../components/Layout/DataTable"
import { roles } from "../../types/permission"
import { UPDATE_USER } from "../../graphql/mutations"
import useGetMyPermissions from "../../hooks/useGetMyPermissions"
import { AdminPermissions } from "../../constants/permissions"
import { getFormattedDate } from "../../utils/date"
import { AdminPaths } from "../../constants/paths"
import { useNavigate } from "react-router-dom"
import ContainerFluid from "../../components/styled/ContainerFluid.styled"
import Button from "../../components/styled/Button.styled"
import { validateLeadingTrailingSpaces } from "../../utils/formUtils"

const {
  UPDATE_USER_EMAIL,
  UPDATE_USER_ROLE,
  UPDATE_USER_STATUS,
  VIEW_ADD_USER,
} = AdminPermissions

const { ADD_USER_PATH } = AdminPaths

const currentPage = 1
const limitPerPage = 10

const Users = () => {
  const navigate = useNavigate()

  const userStatus = {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
  }

  const { permissions } = useGetMyPermissions()

  const [form] = Form.useForm()
  const { Option } = Select
  const [alertMessage, setAlertMessage] = useState<JSX.Element>()
  const [tableData, setTableData] = useState<users[]>([])
  const [totalPages, setTotalPages] = useState<pages>()
  const [editable, setEditable] = useState<number>()
  const [roles, setRoles] = useState<roles[]>([])
  const [searchString, setSearchString] = useState<string>()

  const [updateUser] = useMutation(UPDATE_USER)
  const [resendEmail] = useLazyQuery(RESEND_EMAIL_TO_USER)

  const [getUserRoles] = useLazyQuery(GET_USER_ROLES, {
    onCompleted(data) {
      setRoles(data.roles)
    },
    onError(err) {
      setAlertMessage(() => {
        return <Alert type="error" message={err.message}></Alert>
      })
    },
  })

  const [getUsers, { loading }] = useLazyQuery(GET_USERS_WITH_PAGINATION, {
    onCompleted(data) {
      const _users = data.usersWithPagination.users
      setTotalPages({
        currentPage: data.usersWithPagination.currentPage,
        limit: limitPerPage,
        totalDocs: data.usersWithPagination.totalDocs,
        totalPages: data.usersWithPagination.totalPages,
      })
      setTableData([])
      _users.forEach((_user: any, index: number) => {
        let user = {
          key: index,
          name: _user.name,
          isActive:
            _user.isActive === null || _user.isActive === undefined
              ? true
              : _user.isActive,
          userName: _user.userName,
          userEmail: _user.userEmail,
          userType: _user.userType,
          createdAt: _user.createdAt,
          userID: _user._id,
          registeredDate: _user.registeredDate,
          roleID: _user.roleData?.find((role: any) => role._id)._id,
          roleCode: _user.roleData?.find((role: any) => role.roleCode).roleCode,
          roleName: _user.roleData?.find((role: any) => role.roleName).roleName,
        }
        setTableData((oldArr: any) => [...oldArr, user])
      })
    },
    onError(err) {
      setAlertMessage(() => {
        return <Alert type="error" message={err.message}></Alert>
      })
    },
    fetchPolicy: "network-only",
  })

  const resetState = () => {
    setTimeout(() => {
      setAlertMessage(undefined)
    }, 3000)
  }

  const updateHandler = async (record: users) => {
    try {
      const {
        userEmail,
        userName,
        userType,
        authDealerID,
        authDistrictID,
        authRegionID,
        name,
        roleID,
        userID,
        isActive,
      } = record
      const res = await updateUser({
        variables: {
          input: {
            userEmail,
            userName,
            userType,
            authDealerID,
            authDistrictID,
            authRegionID,
            name,
            roleID,
            userID,
            isActive,
          },
        },
      })
      if (res) {
        getUsers({
          variables: {
            limit: limitPerPage,
            page: currentPage,
          },
        })
        setAlertMessage(() => {
          return <Alert type="success" message="Update successful" />
        })
        resetState()
      }
    } catch (err: any) {
      getUsers({
        variables: {
          limit: limitPerPage,
          page: currentPage,
        },
      })
      setAlertMessage(() => {
        return <Alert type="error" message={err.message}></Alert>
      })
      resetState()
    }
  }

  const resendEmailHandler = async (userEmail: string) => {
    try {
      const res = await resendEmail({
        variables: {
          email: userEmail,
        },
      })
      if (res) {
        setAlertMessage(() => {
          return <Alert type="success" message="Email sent" />
        })
        resetState()
      }
    } catch (err) {
      setAlertMessage(() => {
        return <Alert type="error" message={(err as Error).message}></Alert>
      })
      resetState()
    }
  }

  useEffect(() => {
    getUsers({
      variables: {
        limit: limitPerPage,
        page: 1,
      },
    })
    getUserRoles()
  }, [getUsers, getUserRoles])

  const columns: ColumnsType<users> = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => {
        if (record.key === editable && permissions.includes(UPDATE_USER_EMAIL)) {
          return (
            <Form form={form}>
              <Form.Item
                name={"name"}
                rules={[
                  {
                    required: true,
                    message: "Please enter name",
                  },
                  validateLeadingTrailingSpaces()
                ]}
                noStyle
              >
                <Input
                  onChange={(e) => {
                    record.name = e.target.value
                  }}
                />
              </Form.Item>
            </Form>
          )
        } else {
          return <>{text}</>
        }
      },
    },
    {
      title: "Email",
      dataIndex: "userEmail",
      width: "360px",
      render: (text, record) => {
        if (
          record.key === editable &&
          !record.registeredDate &&
          permissions.includes(UPDATE_USER_EMAIL)
        ) {
          return (
            <>
              <Form form={form}>
                <Form.Item
                  name={"userEmail"}
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                  noStyle
                >
                  <Input
                    onChange={(e) => {
                      record.userEmail = e.target.value
                      record.userName = e.target.value
                    }}
                  />
                </Form.Item>
              </Form>
            </>
          )
        } else {
          return <>{text}</>
        }
      },
    },
    {
      title: "Role",
      dataIndex: "roleName",
      width: "240px",
      key: "userType",
      render: (text, record) => {
        if (record.key === editable && permissions.includes(UPDATE_USER_ROLE)) {
          return (
            <Select
              className="w-100"
              defaultValue={text}
              onChange={(value) => {
                record.roleID = value
              }}
            >
              {roles.map((role, index) => {
                return (
                  <Option key={index} value={role._id}>
                    {role.roleName}
                  </Option>
                )
              })}
            </Select>
          )
        } else {
          return <>{text}</>
        }
      },
    },
    {
      title: "Status",
      dataIndex: "isActive",
      width: "160px",
      key: "isActive",
      render: (text, record) => {
        if (
          editable === record.key &&
          permissions.includes(UPDATE_USER_STATUS)
        ) {
          return (
            <>
              <Select
                defaultValue={text}
                className="w-100"
                onChange={(value) => {
                  record.isActive = value
                }}
              >
                <Option value={true}>Active</Option>
                <Option value={false}>Inactive</Option>
              </Select>
            </>
          )
        } else {
          return <>{text === true ? userStatus.ACTIVE : userStatus.INACTIVE}</>
        }
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      render: (value) => getFormattedDate(value),
    },
    {
      title: "Registered Date",
      dataIndex: "registeredDate",
      render: (value) => getFormattedDate(value),
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <>
            {/** Show edit button when has permission to update any user role or status,
             * Or has permission to update email and user is not registered
             */}
            {([UPDATE_USER_ROLE, UPDATE_USER_STATUS].some((permission) =>
              permissions.includes(permission)
            ) ||
              (permissions.includes(UPDATE_USER_EMAIL) &&
                !record.registeredDate)) && (
                <span
                  className="custom-link mx-2"
                  hidden={record.key !== editable ? false : true}
                  onClick={() => {
                    setEditable(record.key)
                    getUserRoles()
                    form.setFieldsValue({
                      userEmail: record.userEmail,
                      name: record.name
                    })
                  }}
                >
                  Edit
                </span>
              )}

            {!record.registeredDate && (
              <span
                className="custom-link mx-2"
                hidden={record.key !== editable ? false : true}
                onClick={() => {
                  resendEmailHandler(record.userEmail)
                }}
              >
                Resend Email
              </span>
            )}

            <span
              className="custom-link mx-2"
              hidden={record.key === editable ? false : true}
              onClick={() => {
                form
                  .validateFields()
                  .then(() => {
                    updateHandler(record)
                    setEditable(undefined)
                  })
                  .catch((err) => {
                    window.scrollTo(0, 100)
                    setAlertMessage(() => {
                      return (
                        <Alert
                          type="error"
                          message={"Entered data is either empty or incorrect"}
                        ></Alert>
                      )
                    })
                    resetState()
                  })
              }}
            >
              Save
            </span>
            <span
              className="custom-link mx-2"
              hidden={record.key === editable ? false : true}
            >
              <Popconfirm
                title="Cancel changes?"
                onConfirm={() => {
                  setEditable(undefined)
                  getUsers({
                    variables: { limit: limitPerPage, page: currentPage },
                  })
                  resetState()
                }}
              >
                Cancel
              </Popconfirm>
            </span>
          </>
        )
      },
    },
  ]

  return (
    <ContainerFluid>
      <Heading
        heading="Administrators"
        render={
          permissions.includes(VIEW_ADD_USER) && (
            <Button onClick={() => navigate(ADD_USER_PATH)}>
              + Add Administrator
            </Button>
          )
        }
      />

      <div className="col-md-6 mb-3">{alertMessage}</div>

      <h4>Search</h4>
      <div className="col-md-6 mb-3">
        <Input.Search
          allowClear
          onChange={(e) => {
            if (e.target.value.length === 0) {
              getUsers({
                variables: {
                  limit: limitPerPage,
                  page: 1,
                },
              })
            }
          }}
          onSearch={(e) => {
            setSearchString(e)
            getUsers({
              variables: {
                searchQuery: e,
                limit: limitPerPage,
                page: 1,
              },
            })
          }}
        />
      </div>

      <DataTable
        tableData={tableData}
        totalPages={totalPages}
        page={(cp) => {
          getUsers({
            variables: {
              searchQuery: searchString,
              limit: limitPerPage,
              page: cp,
            },
          })
        }}
        showCheck={false}
        columnData={columns}
        scrollX="max-content"
        loading={loading}
      />
    </ContainerFluid>
  )
}

export default Users
