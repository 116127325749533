import { Form, Input } from "antd"

type Props = { disabled?: boolean }

const JobTitle = ({ disabled }: Props) => {
  return (
    <Form.Item
      rules={[{ required: true, message: "Please enter a job title" }]}
      name="jobTitle"
      label="Job Title"
    >
      <Input autoComplete="new-password" disabled={disabled} />
    </Form.Item>
  )
}

export default JobTitle
