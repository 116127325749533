import { Form, Alert, Input } from "antd"
import { useMutation } from "@apollo/client"
import { UploadChangeParam } from "antd/es/upload"
import { useState, useEffect } from "react"

import { errorTimeout } from "../../constants/general"
import RESET_ALERT from "../../constants/resetAlert"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { setSelectedNote } from "../../redux/ui/uiSlice"
import DownloadPopover from "../../components/DownloadPopover"
import FileUpload from "../../components/FileUpload"
import Spinner from "../../components/Spinner"
import { Heading } from "../../components/styled/Heading"
import { CREATE_UPDATE_NOTE } from "./graphql"
import { INote } from "./types"
import Button from "../../components/styled/Button.styled"

/**
 * Form used in adding and editing a note
 * Refetches notes after any updates
 */
const NotesForm = ({ initialValues }: { initialValues?: INote }) => {
  const dispatch = useAppDispatch()
  const [blockSubmit, setBlockSubmit] = useState(false)
  const [alert, setAlert] = useState(RESET_ALERT)

  const isEdit = !!initialValues

  const [form] = Form.useForm()

  const { selectedClaimId, selectedNote } = useAppSelector((state) => state.ui)

  const [createUpdateNote, { loading }] = useMutation(CREATE_UPDATE_NOTE, {
    onError(error) {
      setAlert({
        type: "error",
        message: error.message,
      })
    },
    refetchQueries: ["NotesByClaimId"],
  })

  const onFinish = (
    values: INote & {
      documents: UploadChangeParam
    }
  ) => {
    if (blockSubmit) {
      return
    }

    const commonApiPayload = {
      claimId: selectedClaimId,
      note: values.note,
      document: values.documents?.fileList.map((file) => file.originFileObj),
    }

    // Update Note
    if (initialValues) {
      createUpdateNote({
        variables: {
          note: {
            _id: selectedNote?._id,
            ...commonApiPayload,
          },
        },
        onCompleted() {
          setAlert({
            type: "success",
            message: "Note updated successfully",
          })
          dispatch(setSelectedNote())
        },
      })
      return
    }

    // Create Note
    createUpdateNote({
      variables: {
        note: commonApiPayload,
      },
      onCompleted() {
        setAlert({
          type: "success",
          message: "Note added successfully",
        })
        form.resetFields()
      },
    })
  }

  // Reset alert
  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert(RESET_ALERT)
    }, errorTimeout)

    return () => clearTimeout(timer)
  }, [alert])

  return (
    <>
      <Heading heading={isEdit ? "Edit Note" : "Add Note"} />

      <Form
        onFinish={onFinish}
        requiredMark={false}
        layout="vertical"
        initialValues={initialValues}
        form={form}
      >
        {/* Note Textarea */}
        <Form.Item
          label="Note"
          name="note"
          rules={[{ required: true, message: "Please enter a note" }]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        {/* Uploader */}
        <Form.Item>
          <FileUpload
            setBlockSubmit={setBlockSubmit}
            label={
              isEdit ? (
                <EditNoteDocumentLabel urls={initialValues.noteDocumentUrl} />
              ) : (
                "Document"
              )
            }
            name="documents"
          />
        </Form.Item>

        {/* Loader & Alert */}
        {loading && (
          <div className="mb-3">
            <Spinner />
          </div>
        )}
        {alert.message && <Alert className="mb-3" {...alert} />}

        {/* Submit Button */}
        <div className="row justify-content-center">
          <div className="col-md-6">
            <Button type="submit">{isEdit ? "Update Note" : "Add Note"}</Button>
          </div>
        </div>
      </Form>
    </>
  )
}

/**
 * For showing uploaded documents in edit form
 */
const EditNoteDocumentLabel = ({ urls }: { urls: string[] }) => {
  return (
    <>
      Document (
      <DownloadPopover
        urls={urls}
        label="Uploaded Documents"
        title="Download"
      />
      )
    </>
  )
}

export default NotesForm