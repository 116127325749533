import { Link } from "./CustomLink"
import { AdminPaths } from "../../constants/paths"
import { AdminPermissions } from "../../constants/permissions"
import DropdownNavbar from "./DropdownNavbar"

const { VIEW_PROGRAM_COMMUNICATIONS, VIEW_ANNOUNCEMENT } = AdminPermissions
const { PROGRAM_COMMUNICATIONS_PATH, ANNOUNCEMENT_PATH } = AdminPaths

const items = [
  // TODO
  // Coming Soon!
  // When the dashboard is ready, this item should link to what is currently being used as the homepage
  // {
  //   label: <Link to={CURRENT_INCENTIVES_PATH}>Participant Site Announcements</Link>,
  //   permission: VIEW_CURRENT_INCENTIVES,
  //   key: "1",
  // },
  {
    label: <Link to={PROGRAM_COMMUNICATIONS_PATH}>Program Communications</Link>,
    permission: VIEW_PROGRAM_COMMUNICATIONS,
    key: "2",
  },
  {
    label: <Link to={ANNOUNCEMENT_PATH}>Participant Site Announcement</Link>,
    permission: VIEW_ANNOUNCEMENT,
    key: "3",
  },
]

type CommunicationsNavItemProps = {
  toggleCollapse: () => void
}

const CommunicationsNavItem = ({
  toggleCollapse,
}: CommunicationsNavItemProps) => {
  return (
    <DropdownNavbar
      label="Communications"
      items={items}
      toggleCollapse={toggleCollapse}
    />
  )
}

export default CommunicationsNavItem
