import { Link } from "./CustomLink"
import { CommonPaths } from "../../constants/paths"
import { CommonPermissions } from "../../constants/permissions"
import DropdownNavbar from "./DropdownNavbar"

const { VIEW_RESOURCES, VIEW_CONTACTUS } = CommonPermissions

const { RESOURCES_FAQ_PATH, RESOURCES_RULES_PATH, CONTACTUS_PATH } = CommonPaths

const items = [
  {
    label: <Link to={RESOURCES_FAQ_PATH}>FAQs</Link>,
    permission: VIEW_RESOURCES,
    key: "1",
  },
  {
    label: <Link to={RESOURCES_RULES_PATH}>Program Rules</Link>,
    permission: VIEW_RESOURCES,
    key: "2",
  },
  {
    label: <Link to={CONTACTUS_PATH}>Contact Us</Link>,
    permission: VIEW_CONTACTUS,
    key: "3",
  },
]

type ResourcesNavItemProps = {
  toggleCollapse: () => void
}

const ResourcesNavItem = ({ toggleCollapse }: ResourcesNavItemProps) => {
  return (
    <DropdownNavbar
      label="Resources"
      items={items}
      toggleCollapse={toggleCollapse}
    />
  )
}

export default ResourcesNavItem
