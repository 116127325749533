import { Navigate, Outlet, useLocation } from "react-router-dom"
import { useAuthStatus } from "../hooks/useAuthStatus"
import { Spin } from "antd"

function AuthorisedRoute() {
  const { loggedIn, checkingStatus } = useAuthStatus()
  const location = useLocation()

  if (checkingStatus) {
    return <Spin />
  }

  return (
    <>
      {loggedIn ? (
        <Outlet />
      ) : (
        <Navigate to="/signin" state={{ from: location }} />
      )}
    </>
  )
}

export default AuthorisedRoute
