import { EditOutlined, CloseOutlined } from "@ant-design/icons"
import { useMutation } from "@apollo/client"
import { Form, Input } from "antd"
import { useState } from "react"
import styled from "styled-components"
import globals from "../../constants/globals"
import { AdminPermissions } from "../../constants/permissions"
import { CREATE_HEADER } from "../../graphql/mutations"
import { GET_HEADER } from "../../graphql/queries"
import useGetMyPermissions from "../../hooks/useGetMyPermissions"
import useGetProgramHeader from "../../hooks/useGetProgramHeader"
import { SecondaryButton } from "../styled/Button.styled"

const { IS_ADMIN } = globals

const Icon = styled.button`
  position: absolute;
  top: 0;
  right: -24px;
  background-color: transparent;
  border: none;
`

const { UPDATE_HOME_PROGRAM_HEADER } = AdminPermissions

const ProgramHeader = () => {
  const [editable, setEditable] = useState<boolean>(false)

  const { permissions } = useGetMyPermissions()

  const { header } = useGetProgramHeader()

  const toggleEdit = () => setEditable((val) => !val)

  const [createHeader] = useMutation(CREATE_HEADER)

  const onFinish = (values: any) => {
    createHeader({
      variables: {
        header: {
          content: values?.text?.trim() || "",
        },
      },
      refetchQueries: [{ query: GET_HEADER }, "GetHeader"],
    })
    toggleEdit()
  }

  const hasPermissionToEdit =
    permissions.includes(UPDATE_HOME_PROGRAM_HEADER) && IS_ADMIN

  return hasPermissionToEdit ? (
    <div className="position-relative">
      {editable ? (
        <Form onFinish={onFinish} initialValues={{ text: header }}>
          <Form.Item name="text" noStyle>
            <Input.TextArea
              maxLength={300}
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item>
          <div className="row justify-content-end pt-2">
            <div className="col-3">
              <SecondaryButton type="submit">Save</SecondaryButton>
            </div>
          </div>
        </Form>
      ) : (
        <>{header}</>
      )}
      <Icon onClick={toggleEdit} className="pe-0">
        {editable ? <CloseOutlined /> : <EditOutlined />}
      </Icon>
    </div>
  ) : (
    <div>{header}</div>
  )
}

export default ProgramHeader
