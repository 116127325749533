import { Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import { ClaimStatusColor, IClaimStatus } from "../../constants/claimsStatus"
import { IClaim } from "../../types/graphqlResponse"
import { formatAmount, getFormattedDate } from "../../utils/date"
import insertIntoArray from "../../utils/insertIntoArray"
import DownloadPopover from "../DownloadPopover"
import NotesIcon from "../../features/ClaimNotes/NotesIcon"
import { AIStatusComponent } from "./AIStatusComponent"

const firstName: ColumnsType<IClaim> = [
  {
    title: "First Name",
    fixed: "left",
    render(_, record) {
      return <NotesIcon record={record} recordKey="firstName" />
    },
  },
]

const lastName: ColumnsType<IClaim> = [
  {
    title: "Last Name",
    dataIndex: "lastName",
  },
]

const vehicle: ColumnsType<IClaim> = [
  {
    title: "Vehicle",
    dataIndex: "vehicle_category1",
  },
]

const partNumber: ColumnsType<IClaim> = [
  {
    title: "Part number",
    dataIndex: "partNumber_category2",
  },
]

const accessory: ColumnsType<IClaim> = [
  {
    title: "Accessory",
    dataIndex: "accessoryBasic_category3",
  },
]

const orderID: ColumnsType<IClaim> = [
  {
    title: "RO #",
    dataIndex: "orderId",
  },
]

const quantity: ColumnsType<IClaim> = [
  {
    title: "Quantity",
    dataIndex: "quantity",
  },
]

const awardAmount: ColumnsType<IClaim> = [
  {
    title: "Award Amount",
    dataIndex: "awardValue",
    render(value) {
      return value ? formatAmount(value) : "$0.00"
    },
  },
]

const dateOfSale: ColumnsType<IClaim> = [
  {
    title: "Sale Date",
    dataIndex: "dateOfSale",
    render: (value) => getFormattedDate(value),
  },
]

const claimDate: ColumnsType<IClaim> = [
  {
    title: "Claim Date",
    dataIndex: "createdAt",
    render: (value) => getFormattedDate(value),
  },
]

const declineReasonTextColumn: ColumnsType<IClaim> = [
  {
    title: "Decline Reason",
    dataIndex: "declinedReasons",
  },
]

const approvedDeclinedDateColumn: ColumnsType<IClaim> = [
  {
    title: "Approved / Declined Date",
    dataIndex: "statusUpdatedAt",
    render: (value) => getFormattedDate(value),
  },
]

const approvedDeclinedByColumn: ColumnsType<IClaim> = [
  {
    title: "Approved / Declined By",
    dataIndex: "approvedOrDeclinedByName",
  },
]

const dealerNameColumn: ColumnsType<IClaim> = [
  {
    title: "Dealer Name",
    dataIndex: "dealerName",
  },
]

const trackingNumber: ColumnsType<IClaim> = [
  {
    title: "Tracking Number",
    dataIndex: "trackingNumber",
  },
]

const uploadedReceiptsColumns: ColumnsType<IClaim> = [
  {
    title: "Uploaded RO",
    dataIndex: "claimDocumentUrl",
    render: (_, { claimDocumentUrl }) =>
      claimDocumentUrl.length > 0 && (
        <DownloadPopover urls={claimDocumentUrl} title="Download Receipts" />
      ),
  },
]

const status: ColumnsType<IClaim> = [
  {
    title: "Status",
    dataIndex: "status",
    render: (val: IClaimStatus, record) => {
      return (
        <>
          <Tag color={record.isProcessed ? "success" : ClaimStatusColor[val]}>
            {record.isProcessed ? "FUNDED" : val.toUpperCase()}
          </Tag>
          <NotesIcon record={record} recordKey="status" />
        </>
      )
    },
  },
]

const productDescription: ColumnsType<IClaim> = [
  {
    title: "Product Description",
    width: "300px",
    dataIndex: "productDescription",
    key: "3",
    render: (val) => {
      return (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {val}
        </div>
      )
    },
  },
]

const claimId: ColumnsType<IClaim> = [
  {
    title: "Claim ID",
    dataIndex: "internalClaimID",
  },
]

const fundedDate: ColumnsType<IClaim> = [
  {
    title: "Funded Date",
    dataIndex: "orderFundedAt",
    render: (orderFundedAt) => getFormattedDate(orderFundedAt),
  },
]

const shipDate: ColumnsType<IClaim> = [
  {
    title: "Ship Date",
    dataIndex: "shippedDate",
    render: (shippedDate) => getFormattedDate(shippedDate),
  },
]

const aiStatus: ColumnsType<IClaim> = [
  {
    title: "AI Status",
    dataIndex: "id",
    render: (_, record) =>
      record.AIStatus && <AIStatusComponent record={record} />,
  },
]

const region: ColumnsType<IClaim> = [
  {
    title: "Region",
    dataIndex: "regionId",
  },
]

const district: ColumnsType<IClaim> = [
  {
    title: "District",
    dataIndex: "districtId",
  },
]

const dealerCode: ColumnsType<IClaim> = [
  {
    title: "Dealer Code",
    dataIndex: "dealerCode",
  },
]

//this is for claims table
const normalClaimColumns: ColumnsType<IClaim> = [
  ...firstName,
  ...lastName,
  ...claimId,
  ...trackingNumber,
  ...aiStatus,
  ...status,
  ...vehicle,
  ...partNumber,
  ...accessory,
  ...orderID,
  ...quantity,
  ...awardAmount,
  ...dateOfSale,
  ...claimDate,
  ...approvedDeclinedDateColumn,
  ...approvedDeclinedByColumn,
  ...declineReasonTextColumn,
  ...fundedDate,
  ...shipDate,
  ...region,
  ...district,
  ...dealerCode,
  ...dealerNameColumn,
  ...uploadedReceiptsColumns,
].map((column, key) => ({
  ...column,
  key,
  fixed:
    ["First Name", "Last Name", "Claim ID"].includes(column.title as string) &&
    "left",
}))

//this is for edit claims table
const normalEditClaimColumns: ColumnsType<IClaim> = [
  ...firstName,
  ...lastName,
  ...claimId,
  ...trackingNumber,
  ...status,
  ...dealerNameColumn,
  ...vehicle,
  ...partNumber,
  ...accessory,
  ...orderID,
  ...quantity,
  ...awardAmount,
  ...dateOfSale,
  ...claimDate,
  ...approvedDeclinedDateColumn,
  ...approvedDeclinedByColumn,
  ...declineReasonTextColumn,
  ...fundedDate,
  ...dealerNameColumn,
  ...uploadedReceiptsColumns,
].map((column, key) => ({ ...column, key }))

/** Consumer Side Claim Table */
const myAccountClaimColumns: ColumnsType<IClaim> = [
  ...status,
  ...dealerNameColumn,
  ...vehicle,
  ...partNumber,
  ...accessory,
  ...claimId,
  ...productDescription,
  ...orderID,
  ...quantity,
  ...awardAmount,
  ...dateOfSale,
  ...claimDate,
  ...approvedDeclinedDateColumn,
  ...declineReasonTextColumn,
  ...fundedDate,
  ...shipDate,
  ...uploadedReceiptsColumns,
].map((column, key) => ({ ...column, key }))

const claimsApprovalColumns: ColumnsType<IClaim> = [
  ...firstName,
  ...lastName,
  ...claimId,
  ...dealerNameColumn,
  ...vehicle,
  ...partNumber,
  ...accessory,
  ...orderID,
  ...quantity,
  ...awardAmount,
  ...dateOfSale,
  ...claimDate,
]
const claimsApprovalColumnsnew: ColumnsType<IClaim> = [
  ...firstName,
  ...lastName,
  ...claimId,
  ...trackingNumber,
  ...aiStatus,
  ...dealerNameColumn,
  ...vehicle,
  ...partNumber,
  ...accessory,
  ...orderID,
  ...quantity,
  ...awardAmount,
  ...dateOfSale,
  ...claimDate,
]

const pendingClaimsColumns: ColumnsType<IClaim> = [
  ...insertIntoArray(3, claimsApprovalColumns, aiStatus),
  ...uploadedReceiptsColumns,
].map((column, key) => ({ ...column, key }))

const approvedClaimsColumns: ColumnsType<IClaim> = [
  ...claimsApprovalColumnsnew,
  ...approvedDeclinedDateColumn,
  ...approvedDeclinedByColumn,
  ...fundedDate,
  ...dealerNameColumn,
  ...uploadedReceiptsColumns,
].map((column, key) => ({ ...column, key }))

const declinedClaimsColumns: ColumnsType<IClaim> = [
  ...insertIntoArray(3, approvedClaimsColumns, declineReasonTextColumn),
]
  .filter((col) => !["trackingNumber", "orderFundedAt"].includes(col.dataIndex))
  .map((column, key) => ({ ...column, key }))

export {
  pendingClaimsColumns,
  approvedClaimsColumns,
  declinedClaimsColumns,
  normalEditClaimColumns,
  normalClaimColumns,
  myAccountClaimColumns,
}
