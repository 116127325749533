import { message } from "antd"
import { useEffect } from "react"
import { useAppSelector } from "../../redux/hooks"

export default function Alert() {
  const [messageApi, contextHolder] = message.useMessage()

  const { alert } = useAppSelector((state) => state.ui)

  useEffect(() => {
    if (alert) {
      messageApi.open({
        ...alert,
        duration: 3,
      })
    }
  }, [alert])

  return <>{contextHolder}</>
}
