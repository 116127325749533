import { useQuery } from "@apollo/client"
import { Alert, Table } from "antd"
import { useEffect, useState } from "react"

import { errorTimeout } from "../../constants/general"
import RESET_ALERT from "../../constants/resetAlert"
import { useAppSelector } from "../../redux/hooks"
import { Heading } from "../../components/styled/Heading"
import EditNoteModal from "./EditModal"
import { GET_NOTES } from "./graphql"
import { IGetNotesResponse, INote } from "./types"
import notesColumns from "./TableColumns"

const NotesTable = () => {
  const pageSize = 5
  const [alert, setAlert] = useState(RESET_ALERT)

  const { selectedClaimId } = useAppSelector((state) => state.ui)

  const { data, loading } = useQuery<IGetNotesResponse>(GET_NOTES, {
    variables: {
      claimId: selectedClaimId,
    },
    skip: !selectedClaimId,
    onError(error) {
      setAlert({
        type: "error",
        message: error.message,
      })
    },
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert(RESET_ALERT)
    }, errorTimeout)

    return () => clearTimeout(timer)
  }, [alert])

  return (
    <div className="pt-5">
      <Heading heading="Notes" />
      {alert.message && <Alert className="mb-3" {...alert} />}
      <Table<INote>
        key="_id"
        loading={loading}
        scroll={{ x: "max-content" }}
        columns={notesColumns}
        dataSource={data?.notesByClaimId.notes}
        pagination={{
          pageSize,
        }}
      />
      <EditNoteModal />
    </div>
  )
}

export default NotesTable