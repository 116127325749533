import { Form, Input } from "antd"
import { validateLeadingTrailingSpaces } from "../../utils/formUtils"

const DealerNameNew = () => {
  return (
    <Form.Item
      name="dealerShopName"
      label="Dealer Name"
      rules={[
        { required: true, message: "Please enter a dealer name" },
        validateLeadingTrailingSpaces(),
      ]}
    >
      <Input autoComplete="new-password" />
    </Form.Item>
  )
}

export default DealerNameNew
