import globals from "../../constants/globals"
import { InfoContainer } from "./styled/Components.styled"

const {
  CONTACTUS_NUMBER,
  CONTACTUS_TIMINGS,
  CARDHOLDER_SERVICES_CONTACT,
  CARDHOLDER_SERVICES_EMAIL,
  CONTACTUS_TIMINGS_FRIDAY
} = globals

const ContactUsInformation = () => {
  return (
    <InfoContainer>
      <h5>
        <strong>Contact by Phone</strong>
      </h5>
      <p>{CONTACTUS_NUMBER}</p>
      <h5>
        <strong>Hours Of Operation</strong>
      </h5>
      <p>{CONTACTUS_TIMINGS}</p>
      <h5>
        <strong>Cardholder Services Contact Information</strong>
      </h5>
      <p>
        {CARDHOLDER_SERVICES_CONTACT}
        <br />
        <a
          href={`https://${CARDHOLDER_SERVICES_EMAIL}`}
          target="_blank"
          rel="noreferrer"
          className="custom-link"
        >
          {CARDHOLDER_SERVICES_EMAIL}
        </a>
      </p>
    </InfoContainer>
  )
}

export default ContactUsInformation
