import styled from "styled-components"

const ContactUsContainer = styled.div`
  display: flex;

  div:first-child {
    flex: 1;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 4rem;
  }
`

const FormContainer = styled.div`
  @media screen and (min-width: 992px) {
    max-width: 600px;
  }
  @media screen and (min-width: 768px) {
    min-height: 400px;
    min-width: 400px;
    padding-right: 4rem;
    border-right: 1px solid #dee2e6;
  }
`

const InfoContainer = styled.div`
  @media screen and (min-width: 768px) {
    padding-left: 4rem;
  }
`

export { ContactUsContainer, FormContainer, InfoContainer }
