import { Form, Select } from "antd"
import { states } from "../../constants/enrollmentForm"

type Props = {
  disabled?: boolean
}

const DealerState = ({ disabled = false }: Props) => {
  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: "Please enter a state",
        },
      ]}
      name="dealerState"
      label="Dealer State"
    >
      <Select
        showSearch
        filterOption={(input, option) =>
          (option?.value ?? "")
            .toLowerCase()
            .includes(input.toLowerCase().trim())
        }
        options={states}
        // @ts-ignore
        autoComplete="dealer-state"
        virtual={false}
        disabled={disabled}
      />
    </Form.Item>
  )
}

export default DealerState
