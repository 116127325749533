import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useApolloClient } from "@apollo/client"
import { CommonPaths } from "../constants/paths"
import Header from "../components/Layout/Header"
import { Footer } from "antd/es/layout/layout"
import Container from "../components/styled/Container.styled"
import { logout } from "../redux/auth/authSlice"
import { useAppDispatch } from "../redux/hooks"
import { getSessionId, removeAuthToken, removeSessionId } from "../utils/auth"
import globals from "../constants/globals"
import Spinner from "../components/Spinner"
import { resetUiState } from "../redux/ui/uiSlice"

const { IS_ADMIN } = globals

const SignOut = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const client = useApolloClient()

  const sessionId = getSessionId()

  useEffect(() => {
    removeAuthToken()
    dispatch(logout())
    dispatch(resetUiState())
    client.resetStore()
    if (IS_ADMIN) {
      navigate(CommonPaths.SIGNIN_PATH)
    } else {
      // Logging out consumer using SSO
      if (sessionId) {
        removeSessionId()
      }
    }
  }, [navigate, client, dispatch, sessionId])

  if (sessionId) {
    return (
      <>
        <Header showNone />
        <Container>
          <div className="row justify-content-center py-5">
            <div className="col-xl-4 col-lg-6 col-md-8 col-10 border p-5">
              Signing out...
            </div>
          </div>
        </Container>
        <Footer />
      </>
    )
  }

  return (
    <>
      <Header showNone />
      <Container>
        {IS_ADMIN ? (
          <Spinner />
        ) : (
          <div className="row justify-content-center py-5">
            <div className="col-xl-4 col-lg-6 col-md-8 col-10 border p-5">
              You have been successfully logged out. You may close this page.
            </div>
          </div>
        )}
      </Container>
      <Footer />
    </>
  )
}

export default SignOut
