import DocViewer from '@cyntler/react-doc-viewer'

const ReactDocViewer = ({ file }: { file: string }) => {
    const doc2 = [
        {
            uri: file,
            fileType: 'pdf',
        },
    ]

    return (
        <>
            <DocViewer
                documents={doc2}
                config={{
                    header: {
                        disableFileName: true,

                    },
                    pdfVerticalScrollByDefault: true,


                }}
            />
        </>
    )
}

export default ReactDocViewer
