import { ConfigProvider, Dropdown } from "antd"
import { CaretDownFilled } from "@ant-design/icons"
import globals from "../../constants/globals"
import useGetMyPermissions from "../../hooks/useGetMyPermissions"
import { Key, ReactNode } from "react"
import { IPermission } from "../../types/permission"

type DropdownNavbarProps = {
  toggleCollapse: () => void
  items: {
    label: ReactNode
    permission: IPermission
    key: Key
  }[]
  label: string
}

const DropdownNavbar = ({
  toggleCollapse,
  items,
  label,
}: DropdownNavbarProps) => {
  const { permissions } = useGetMyPermissions()

  return (
    <li
      className="nav-item cursor-pointer"
      onClick={(e) => e.stopPropagation()}
    >
      <ConfigProvider
        theme={{
          token: {
            controlItemBgHover: globals.PRIMARY_COLOR,
            borderRadiusSM: 0,
          },
        }}
      >
        <Dropdown
          overlayClassName="dropdown-navbar"
          menu={{
            items: items?.filter((item) =>
              permissions.includes(item.permission)
            ),
            onClick: toggleCollapse,
          }}
          arrow={{ pointAtCenter: true }}
          placement="bottom"
          trigger={["hover"]}
        >
          <div className="nav-link">
            {label} <CaretDownFilled />
          </div>
        </Dropdown>
      </ConfigProvider>
    </li>
  )
}

export default DropdownNavbar
