import dayjs from "dayjs"
import { dateFormat } from "../constants/general"

type Props = { linkLabel: string; date?: string; onClick: () => void }

const ActiveUploaderFileWrapper = ({ date, linkLabel, onClick }: Props) => {
  const lastUpdated = dayjs(date).format(dateFormat)

  return (
    <div className="d-flex h-100 justify-content-center align-items-end">
      <button
        type="button"
        className="border-0 bg-transparent text-end"
        onClick={onClick}
      >
        <div className="custom-link">{linkLabel}</div>
        <div className="text-muted text-end">
          (File Last Updated: {lastUpdated})
        </div>
      </button>
    </div>
  )
}

export default ActiveUploaderFileWrapper
