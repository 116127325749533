import { DownloadOutlined } from "@ant-design/icons"
import { useLazyQuery } from "@apollo/client"
import styled from "styled-components"
import { GET_INCENTIVE_REPORT } from "../../graphql/queries"

const fields = [
  "category1",
  "category2",
  "productImage",
  "thumbnailImage",
  "category3",
  "accessoryExtended",
  "dlrNet",
  "msrp",
  "beginMY",
  "endMY",
  "labor",
  "category4",
  "productDescription",
  "disclaimer",
  "accessoryVideo",
  "notes",
  "mobileImage",
  "mobileThumbnailImage",
  "mobileFlag",
  "startDate",
  "endDate",
  "rewardValue",
]

/** Component used in the Reports Nav Item to download a report directly */
const DownloadCurrentIncentives = () => {
  const [getUrl] = useLazyQuery<{
    incentivesReport: {
      url: string
    }
  }>(GET_INCENTIVE_REPORT, {
    onCompleted(data) {
      window.location.replace(data.incentivesReport.url)
    },
  })

  const callMutation = () => {
    getUrl({
      variables: { fields },
    })
  }

  return (
    <ButtonContainer onClick={callMutation}>
      Download Current Incentives <DownloadOutlined className="fs-5 ps-2" />
    </ButtonContainer>
  )
}

const ButtonContainer = styled.div`
  &:hover {
    color: white;
  }
`

export default DownloadCurrentIncentives
