import { Form, Input } from "antd"

type Props = { disabled?: boolean }

const WorkEmail = ({ disabled }: Props) => {
  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: "Please enter your work email",
        },
        {
          type: "email",
          message: "Invalid format",
        },
      ]}
      name="workEmail"
      label="Work Email"
    >
      <Input autoComplete="new-password" disabled={disabled} />
    </Form.Item>
  )
}

export default WorkEmail
