export const isMobile: boolean =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )

export const isTouchableScreen: boolean = navigator.maxTouchPoints > 0

export const getStringFromHTML = (string: string) => {
  return string?.replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, "");
};

/**
 * Gives out the AWS file storage name from the URL
 */
export function getFileNameFromAwsUrl(url: string): string {
	return url?.split('.com/')[1]?.split('?')[0]
}

/**
 * Gives the mime type from the file extension provided
 */
export function getMimeTypeFromExt(ext: string) {
	return mimeType[ext as keyof typeof mimeType]
}

const mimeType = {
  jpg: "image/jpeg",
  jpeg: "image/jpg",
  png: "image/png",
  heic: "image/heic",
  HEIC: "image/heic",
  pdf: "application/pdf",
}

export const getBpsAccountUrl = (token: string, type: string) => {
  const baseUrl = process.env.REACT_APP_MQ_URL
  return `${baseUrl}/create-account?redemptionToken=${token}&type=${type}`
}

