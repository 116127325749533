import { AdminSignIn } from "../components/SignIn/AdminSignIn"
import { CustomerSignIn } from "../components/SignIn/CustomerSignIn"
import globals from "../constants/globals"

const { IS_ADMIN } = globals

const SignIn = () => {
  return IS_ADMIN ? <AdminSignIn /> : <CustomerSignIn />
}

export default SignIn
