import { gql } from "@apollo/client"

const GET_NOTES = gql`
query NotesByClaimId($claimId: ID!) {
  notesByClaimId(claimId: $claimId) {
    notes {
      _id
      note
      noteDocumentUrl
      createdByName
      createdAt
      updatedAt
      createdByUserId
      claimId
      isNoteByLoggedInUser
    }
  }
}
`

const CREATE_UPDATE_NOTE = gql`
  mutation CreateNote($note: NoteInput) {
    createNote(note: $note)
  }
`

const DELETE_NOTE = gql`
  mutation DeleteNoteById($noteId: ID!) {
    deleteNoteById(noteId: $noteId)
  }
`

export { GET_NOTES, CREATE_UPDATE_NOTE, DELETE_NOTE }