import { Rule } from "antd/es/form"

const validateLeadingTrailingSpaces = (): Rule => ({
  validator: async (_, value) => {
    if (/(^(\s+)(.*)$)|(^(.*)(\s\s+)$)/.test(value)) {
      return Promise.reject(
        new Error("Please remove any leading or trailing spaces")
      )
    }
  },
})

export { validateLeadingTrailingSpaces }
