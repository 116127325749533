import globals from "../constants/globals"

const authTokenKey = "auth_token"
const refreshTokenKey = "refresh_token"
const sessionIdKey = "session_id"

const { GRAPHQL_ENDPOINT } = globals

const getAuthToken = (): string | null => localStorage.getItem(authTokenKey)
const getRefreshToken = (): string | null =>
  localStorage.getItem(refreshTokenKey)
const removeAuthToken = (): void => {
  localStorage.removeItem(authTokenKey)
  localStorage.removeItem(refreshTokenKey)
}
const INVALID_SESSION_TOKEN = "INVALID_SESSION_TOKEN"

const setAuthToken = ({
  token,
  refresh_token,
}: {
  token: string
  refresh_token: string
}): void => {
  localStorage.setItem(authTokenKey, token)
  localStorage.setItem(refreshTokenKey, refresh_token)
}

// Consumer Side - SSO Log out
const setSessionId = (sessionId: string) => {
  localStorage.setItem(sessionIdKey, sessionId)
}
const getSessionId = () => localStorage.getItem(sessionIdKey)
const removeSessionId = async () => {
  const sessionId = getSessionId()
  localStorage.removeItem(sessionIdKey)

  // Redirect the user, which logs out the session
  window.location.href = `${
    GRAPHQL_ENDPOINT.split("graphql")[0]
  }sso/logout?session=${sessionId}`
}

export {
  getAuthToken,
  setAuthToken,
  removeAuthToken,
  getRefreshToken,
  setSessionId,
  getSessionId,
  removeSessionId,
  INVALID_SESSION_TOKEN,
  authTokenKey,
}
