import { Modal } from "antd"

import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { setSelectedNote } from "../../redux/ui/uiSlice"
import NotesForm from "./Form"

/**
 * Separate Modal for editing a note
 */
const EditNoteModal = () => {
  const dispatch = useAppDispatch()
  const { selectedNote } = useAppSelector((state) => state.ui)

  const onCloseModal = () => {
    dispatch(setSelectedNote())
  }

  return (
    <Modal
      open={!!selectedNote}
      footer={null}
      onCancel={onCloseModal}
      className="announcement-modal"
      maskStyle={{
        backgroundColor: "rgb(0, 0, 0, 0.3)",
      }}
      destroyOnClose
      centered
    >
      <NotesForm initialValues={selectedNote} />
    </Modal>
  )
}

export default EditNoteModal