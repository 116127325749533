import { promotionUploaderSampleFile } from "../../../constants/general"
const SampleFile = () => (
  <div className="d-flex h-100 justify-content-center align-items-end">
    <a href={promotionUploaderSampleFile} download className="custom-link">
      Download Sample File
    </a>
  </div>
)

export { SampleFile }
