import ActiveParticipantFile from "../../components/ParticipantUploader/ActiveParticipantFile"
import Container from "../../components/styled/Container.styled"
import { Heading } from "../../components/styled/Heading"
import SubscriptionFileUploader from "../../components/SubscriptionFileUploader"
import { AdminPermissions } from "../../constants/permissions"
import { PARTICIPANT_UPLOADER } from "../../graphql/mutations"
import { PARTICIPANT_UPLOAD_SUBSCRIPTION } from "../../graphql/subscriptions"
import useGetMyPermissions from "../../hooks/useGetMyPermissions"

const { VIEW_ACTIVE_PARTICIPANT_FILE } = AdminPermissions

const ParticipantUploader = () => {
  const { permissions } = useGetMyPermissions()

  const viewActiveParticipantFile = permissions.includes(
    VIEW_ACTIVE_PARTICIPANT_FILE
  )

  return (
    <Container>
      <div className="row justify-content-center">
        <div className="col-10 col-md-8">
          <Heading
            heading="Upload Participants"
            render={viewActiveParticipantFile && <ActiveParticipantFile />}
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-10 col-md-8">
          <SubscriptionFileUploader
            uploaderMutation={PARTICIPANT_UPLOADER}
            uploaderSubscription={PARTICIPANT_UPLOAD_SUBSCRIPTION}
            responseKey="participantFileUpdateStatus"
          />
        </div>
      </div>
    </Container>
  )
}

export default ParticipantUploader
