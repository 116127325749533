import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { useAppDispatch } from "../redux/hooks"

import { login, setTokenTimeout } from "../redux/auth/authSlice"
import { setAuthToken } from "../utils/auth"

/**
 * Hook used to login a user on admin side
 */
function useSsoSignIn() {
  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()

  // Details from URL
  const token = searchParams.get("token")
  const refreshToken = searchParams.get("refreshToken")
  const tokenTime = searchParams.get("tokenTime")

  useEffect(() => {
    if (token && refreshToken && tokenTime) {
      setAuthToken({
        token: token,
        refresh_token: refreshToken,
      })
      dispatch(setTokenTimeout(tokenTime))
      dispatch(login())
    }
  }, [token, refreshToken, tokenTime, dispatch])

  return {}
}

export { useSsoSignIn }
