import React, { useState } from "react"
import { ConfigProvider, Divider, Menu } from "antd"
import { useLocation, useNavigate } from "react-router-dom"
import Button from "../../components/styled/Button.styled"
import ContainerFluid from "../../components/styled/ContainerFluid.styled"
import { Heading } from "../../components/styled/Heading"
import globals from "../../constants/globals"
import { MenuProps } from "antd"
import { ConsumerPaths } from "../../constants/paths"
import { useMemo } from "react"
import {
  CommonPermissions,
  ConsumerPermissions,
} from "../../constants/permissions"
import useGetMyPermissions from "../../hooks/useGetMyPermissions"
import cardImage from "../../assets/images/PrepaidCard.png"
import CardBalance from "./CardBalance"

const { BPS_MYACCOUNT_HOME } = globals
const {
  MY_ACCOUNT_PATH,
  MY_PROFILE_PATH,
  MY_INBOX_PATH,
  MY_FUNDING_HISTORY_PATH,
} = ConsumerPaths
const { VIEW_MY_CLAIMS, VIEW_MY_PROFILE, VIEW_INBOX } = ConsumerPermissions
const { VIEW_FUNDING_HISTORY } = CommonPermissions

const items = [
  {
    key: "1",
    label: "Claims",
    path: MY_ACCOUNT_PATH,
    permissionCode: VIEW_MY_CLAIMS,
  },
  {
    key: "2",
    label: "My Information",
    path: MY_PROFILE_PATH,
    permissionCode: VIEW_MY_PROFILE,
  },
  {
    key: "3",
    label: "Inbox",
    path: MY_INBOX_PATH,
    permissionCode: VIEW_INBOX,
  },
  {
    key: "4",
    label: "Funding History",
    path: MY_FUNDING_HISTORY_PATH,
    permissionCode: VIEW_FUNDING_HISTORY,
  },
]

const MyAccount = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [selectedKeys, setSelectedKeys] = useState<string[]>([
    // Setting the selected key using URL
    items.find(
      (item) => item.path === pathname || item.path === pathname.slice(0, -1)
    )?.key as string,
  ])
  const [showCardBalance, setShowCardBalance] = useState(false)
  const { permissions } = useGetMyPermissions()

  const onSelect: MenuProps["onSelect"] = ({ key }) => {
    setShowCardBalance(false)// Reset showCardBalance to false when menu item is clicked
    navigate(items.find((item) => item.key === key)?.path || "")
    setSelectedKeys([key])
  }

  const itemsToShow = useMemo(
    () =>
      items
        .filter((item) => permissions.includes(item.permissionCode))
        .map((item) => ({ key: item.key, label: item.label })),
    [permissions]
  )

  const handleActivateCard = () => {
    setShowCardBalance(true)
  }

  return (
    <ContainerFluid className="bg-light px-4 px-md-5">
      <Heading heading="My Account" />

      <div className="row mb-2 justify-content-center bg-white">
        <div className="col-lg-3 col-md-4 pt-4 border">
          <div>
            <div className="d-flex justify-content-center">
              <img
                alt="card"
                src={cardImage}
                width="150px"
                className="py-3"
              />
            </div>

            <Button type="button" onClick={handleActivateCard}>
              Activate Card / Check Balance
            </Button>
          </div>

          <Divider />

          <ConfigProvider
            theme={{
              components: {
                Menu: {
                  colorItemBgSelected: "rgba(0, 0, 0, 0.06)",
                  radiusItem: 0,
                },
              },
            }}
          >
            <Menu
              selectedKeys={selectedKeys}
              onSelect={onSelect}
              items={itemsToShow}
            />
          </ConfigProvider>
        </div>
        {showCardBalance ? (
          <div className="col-lg-9 col-md-8 pt-4 border">
            <CardBalance />
          </div>
        ) : (
          <div className="col-lg-9 col-md-8 pt-4 border">{children}</div>
        )}
      </div>
    </ContainerFluid>
  )
}

export default MyAccount
