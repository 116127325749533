import { useCallback, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import globals from "../constants/globals"
import { CommonPaths } from "../constants/paths"

// Events to listen for
const events = [
  "load",
  "mousemove",
  "mousedown",
  "scroll",
  "keypress",
  "resize",
  "unload",
  "keyup",
  "keydown",
  "click",
]

const { AUTO_LOGOUT_TIME } = globals
const { SIGNOUT_PATH } = CommonPaths

const AutoLogout = () => {
  const navigate = useNavigate()

  const timeoutRef = useRef<number | undefined>()

  // Start the timer
  const startTimer = useCallback(() => {
    timeoutRef.current = window.setTimeout(() => {
      navigate(SIGNOUT_PATH)
    }, AUTO_LOGOUT_TIME)
  }, [navigate])

  // Stop the timer
  const stopTimer = () => {
    window.clearTimeout(timeoutRef.current)
  }

  // Timer reset function
  const resetTimer = useCallback(() => {
    stopTimer()
    startTimer()
  }, [startTimer])

  // Setting initial timer on mount
  useEffect(() => {
    startTimer()
    return stopTimer
  }, [startTimer])

  // Adding event listeners
  useEffect(() => {
    // Adding event listeners on all events
    events.forEach((event) => {
      window.addEventListener(event, resetTimer)
    })

    // Removing event listeners
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer)
      })
    }
  }, [resetTimer])

  return <></>
}

export default AutoLogout
