import { Form, Input } from "antd"

const Email = () => {
  return (
    <Form.Item
      label="Email"
      name="email"
      rules={[
        {
          required: true,
          message: "Please enter your email",
        },
        { type: "email", message: "Invalid format" },
      ]}
    >
      <Input />
    </Form.Item>
  )
}

export default Email
