const trimFormValues = (values: any) => {
  const trimmedValues: any = {}
  Object.keys(values).forEach((key) => {
    if (typeof values[key] === "string") {
      trimmedValues[key] = values[key].trim()
    } else {
      trimmedValues[key] = values[key]
    }
  })
  return trimmedValues
}

export default trimFormValues
