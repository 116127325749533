import styled from "styled-components"
import Spinner from "../Spinner"

const SpinnerContainer = styled.div`
  background-color: hsla(255, 0%, 0%, 0.3);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
`
const FullScreenSpinner = () => (
  <SpinnerContainer>
    <Spinner />
  </SpinnerContainer>
)

export default FullScreenSpinner
