const salesRepSubjects = [
  {
    value: "Kia Sales Claim Inquiry",
    label: "Kia Sales Claim Inquiry",
  },
  {
    value: "My Kia Sales Claim Status",
    label: "My Kia Sales Claim Status",
  },
  {
    value: "My Kia Prepaid Visa Reward Card",
    label: "My Kia Prepaid Visa Reward Card",
  },
  {
    value: "Kia Email Not Received",
    label: "Kia Email Not Received",
  },
  {
    value: "Kia Prepaid Visa Reward Card Expired",
    label: "Kia Prepaid Visa Reward Card Expired",
  },
  {
    value: "Kia Current Incentives Inquiry",
    label: "Kia Current Incentives Inquiry",
  },
  {
    value: "Kia Program Communications Inquiry",
    label: "Kia Program Communications Inquiry",
  },
]

const adminSubjects = [
  {
    value: "Update Kia Sales Claim",
    label: "Update Kia Sales Claim",
  },
  {
    value: "Kia Sales Claim Inquiry",
    label: "Kia Sales Claim Inquiry",
  },
  {
    value: "Kia Sales Claim Status",
    label: "Kia Sales Claim Status",
  },
  {
    value: "Kia Report Inquiry",
    label: "Kia Report Inquiry",
  },
  {
    value: "Kia Uploader Inquiry",
    label: "Kia Uploader Inquiry",
  },
  {
    value: "Kia Current Incentives Inquiry",
    label: "Kia Current Incentives Inquiry",
  },
  {
    value: "Kia Users Inquiry",
    label: "Kia Users Inquiry",
  },
  {
    value: "Kia Program Communications Inquiry",
    label: "Kia Program Communications Inquiry",
  },
]

// Consumer side - subject options when logged out
const loggedOutSubjects = [
  {
    value: "Kia Enrollment Inquiry",
    label: "Kia Enrollment Inquiry",
  },
]

export { adminSubjects, salesRepSubjects, loggedOutSubjects }
