import { useLocation } from "react-router-dom"

import { AdminPaths, ConsumerPaths } from "../../constants/paths"
import { IClaim } from "../../types/graphqlResponse"
import notesSvg from "../../assets/images/notes.svg"
import notesGreenSvg from "../../assets/images/notes-green.svg"
import { useAppDispatch } from "../../redux/hooks"
import { setSelectedClaimId } from "../../redux/ui/uiSlice"
import useGetMyPermissions from "../../hooks/useGetMyPermissions"
import { AdminPermissions } from "../../constants/permissions"

const { CLAIMS_PATH } = AdminPaths

const { MY_ACCOUNT_PATH } = ConsumerPaths

const { VIEW_CLAIM_NOTES } = AdminPermissions

type Props = { record: IClaim; recordKey: "firstName" | "status" }

const NotesIcon = ({ record, recordKey }: Props) => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  const { permissions } = useGetMyPermissions()

  const isClaimsPage =
    (pathname === CLAIMS_PATH || pathname.slice(0, -1) === CLAIMS_PATH) &&
    recordKey === "firstName"

  const isMyAccountTable =
    (pathname === MY_ACCOUNT_PATH ||
      pathname.slice(0, -1) === MY_ACCOUNT_PATH) &&
    recordKey === "status"

  const onClick = () => {
    dispatch(setSelectedClaimId(record.id))
  }

  const hasNotes = record.claimHasNotes

  const hasPermissionForNotes = permissions.includes(VIEW_CLAIM_NOTES)

  return (
    <>
      {recordKey === "firstName" && record[recordKey]}
      {(isClaimsPage || isMyAccountTable) && hasPermissionForNotes && (
        <>
          {" "}
          <img
            src={hasNotes ? notesGreenSvg : notesSvg}
            alt="notes"
            className="cursor-pointer"
            onClick={onClick}
          />
        </>
      )}
    </>
  )
}

export default NotesIcon
