import { Modal } from "antd"
import { LinkButton } from "../styled/Button.styled"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { setDocUrl } from "../../redux/ui/uiSlice"
import { getFileNameFromAwsUrl, getMimeTypeFromExt } from "../../utils/general"
import ReactDocViewer from "../ReactDocViewer"
/**
 * Component for showing PDF and images
 * Uses redux for state management
 */
function MediaViewer() {
  const dispatch = useAppDispatch()

  const docUrl = useAppSelector((state) => state.ui.docUrl)
  const open = !!docUrl

  const fileName = getFileNameFromAwsUrl(docUrl)
  const fileExtension: string | undefined = /(?:\.([^.]+))?$/.exec(fileName)![1]
  const mimeType = getMimeTypeFromExt(fileExtension)
  const isPdf = mimeType === "application/pdf"

  const styleProps = {
    width: isPdf ? "90%" : "70%",
    style: { top: "25px" },
    bodyStyle: {
      height: "90vh",
      display: "flex",
      flexDirection: "column" as const,
    },
  }

  const embedStyleProps = isPdf
    ? { width: "95%", height: "100%" }
    : { maxWidth: "95%", maxHeight: "100%" }

  function onCancel() {
    dispatch(setDocUrl(""))
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      maskStyle={{
        backgroundColor: "rgb(0, 0, 0, 0.3)",
      }}
      footer={null}
      destroyOnClose
      {...styleProps}
    >
      <div className="d-flex flex-grow-1 gap-3 flex-column justify-content-center align-items-center">
        {mimeType ? (
          isPdf ? (
            <ReactDocViewer file={docUrl} />
          ) : (
              <embed src={docUrl} type={mimeType} style={embedStyleProps} />
          )
        ) : (
          <p> Something went wrong</p>
        )}
        {!isPdf && (
          <div>
            <a href={docUrl} download className="custom-link">
              <LinkButton className="mb-3" type="button">
                Download
              </LinkButton>
            </a>
          </div>
        )}
      </div>
    </Modal>
  )
}

export { MediaViewer }
