import { useMutation, useQuery } from "@apollo/client"

import { GET_UPDATED_PARTICIPANT_FILE } from "../../graphql/mutations"
import { GET_DEALER_FILE_UPDATE_TIME } from "../../graphql/queries"
import { GetDealerUpdateTimeResponse, GetFileResponse } from "./types"
import ActiveUploaderFileWrapper from "../ActiveUploaderFileWrapper"

const ActiveParticipantFile = () => {
  const [getFile] = useMutation<GetFileResponse>(GET_UPDATED_PARTICIPANT_FILE)

  const { data } = useQuery<GetDealerUpdateTimeResponse>(
    GET_DEALER_FILE_UPDATE_TIME,
    {
      variables: {
        fileHash: "ParticipantFile",
      },
    }
  )

  const onClick = () => {
    getFile({
      onCompleted: (data) => {
        window.location.replace(data.createParticipantFile.url)
      },
    })
  }

  return (
    <ActiveUploaderFileWrapper
      linkLabel="Active Participant File"
      date={data?.getLatestFileHashDate}
      onClick={onClick}
    />
  )
}

export default ActiveParticipantFile
