import { Form, Select } from "antd"
import {
  adminSubjects,
  loggedOutSubjects,
  salesRepSubjects,
} from "../../constants/contactUsSubjects"
import globals from "../../constants/globals"
import { useAuthStatus } from "../../hooks/useAuthStatus"

const { IS_ADMIN } = globals

const Subject = () => {
  const { loggedIn } = useAuthStatus()

  const options = !loggedIn
    ? loggedOutSubjects
    : IS_ADMIN
    ? adminSubjects
    : salesRepSubjects

  return (
    <Form.Item
      label="Subject"
      name="subject"
      rules={[
        {
          required: true,
          message: "Please select a subject",
        },
      ]}
    >
      <Select options={options} virtual={false} />
    </Form.Item>
  )
}

export default Subject
