import { Heading } from "../../components/styled/Heading"
import Container from "../../components/styled/Container.styled"
import { Spin, Button, Input, Alert } from "antd"
import { useLazyQuery } from "@apollo/client"
import { FUND_ORDER, GET_ALL_ORDERS } from "../../graphql/queries"
import DataTable from "../../components/Layout/DataTable"
import { orders, pages } from "../../types/datatable"
import { useEffect, useState } from "react"
import { ColumnsType } from "antd/es/table"
import { formatAmount, getFormattedDate } from "../../utils/date"

const InvoiceOrder = () => {
  const limitPerPage = 10
  const [alertMessage, setAlertMessage] = useState<JSX.Element>()
  const [tableData, setTableData] = useState<orders[]>([])
  const [totalPages, setTotalPages] = useState<pages>()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [fundOrder] = useLazyQuery(FUND_ORDER, {
    onCompleted(data) {
      if (!!data) {
        setAlertMessage(() => {
          return <Alert type="success" message={"Order funded successfully"} />
        })
        getAllOrders({
          variables: {
            limit: limitPerPage,
            page: currentPage,
          },
        })
      }
    },
    onError(err) {
      setAlertMessage(() => {
        return <Alert type="error" message={err.message} />
      })
    },
    fetchPolicy: "network-only",
  })
  const [getAllOrders, { loading }] = useLazyQuery(GET_ALL_ORDERS, {
    onCompleted(data) {
      if (!!data) {
        const _orders = data.allOrders.orders
        setTotalPages({
          currentPage: data.allOrders.currentPage,
          limit: limitPerPage,
          totalDocs: data.allOrders.totalDocs,
          totalPages: data.allOrders.totalPages,
        })
        setTableData([])
        _orders.map((_order: orders, index: number) => {
          let order = {
            key: index,
            _id: _order._id,
            createdAt: _order.createdAt,
            documentUrl: _order.documentUrl,
            filename: _order.filename,
            funded: _order.funded,
            fundedAt: _order.fundedAt,
            orderTotal: _order.orderTotal,
            status: _order.status,
            orderId: _order.orderId,
          }
          setTableData((oldArr: any) => [...oldArr, order])
          return null
        })
      }
    },
    onError(err) {
      setAlertMessage(() => {
        return <Alert type="error" message={err.message} />
      })
    },
    fetchPolicy: "network-only",
  })

  useEffect(() => {
    getAllOrders({
      variables: {
        limit: limitPerPage,
        page: currentPage,
      },
    })
  }, [getAllOrders, currentPage])

  const columns: ColumnsType<orders> = [
    {
      title: "Invoice Order ID",
      dataIndex: "orderId",
      key: "1",
      fixed: "left",
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "2",
      render: (value) => getFormattedDate(value),
    },
    {
      title: "Order Total",
      dataIndex: "orderTotal",
      key: "3",
      render(value) {
        return value ? formatAmount(value) : "$0.00"
      },
    },
    {
      title: "Document Report",
      dataIndex: "documentUrl",
      key: "4",
      render: (_, record) => {
        return (
          <>
            <a href={`${record.documentUrl}`} download className="custom-link">
              Download
            </a>
          </>
        )
      },
    },
    {
      title: "Funded Date",
      dataIndex: "fundedAt",
      key: "5",
      render: (value) => getFormattedDate(value),
    },
    {
      title: "Action",
      render: (_, record) => {
        if (!!record.funded) {
          return (
            <>
              <Button disabled type="link">
                Funded
              </Button>
            </>
          )
        } else {
          return (
            <>
              <Button
                type="link"
                onClick={() => {
                  fundOrder({ variables: { id: record._id } })
                }}
              >
                Fund Order
              </Button>
            </>
          )
        }
      },
    },
  ]

  return (
    <Container>
      <Heading heading="Invoice Order" />
      <div className="col-6 mb-2">{alertMessage}</div>
      <div className="row mb-3">
        <h4>Search</h4>
        <div className="col-12 col-md-6">
          <Input.Search
            allowClear
            onChange={(e) => {
              if (e.target.value.length === 0) {
                getAllOrders({
                  variables: {
                    limit: limitPerPage,
                    page: 1,
                  },
                })
              }
            }}
            onSearch={(e) => {
              getAllOrders({
                variables: {
                  searchQuery: e,
                  limit: limitPerPage,
                  page: 1,
                },
              })
            }}
          />
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-12">
          {loading ? (
            <Spin
              className="mt-5 d-flex justify-content-center"
              size="large"
            ></Spin>
          ) : (
            <DataTable
              tableData={tableData}
              totalPages={totalPages}
              page={(cp) => {
                setCurrentPage(Number(cp))
              }}
              showCheck={false}
              columnData={columns}
              scrollX="max-content"
            />
          )}
        </div>
      </div>
    </Container>
  )
}

export default InvoiceOrder
