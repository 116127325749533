import { Form } from "antd"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

const modules = {
  toolbar: [
    ["bold", "underline", "italic"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
}

var formats = ["bold", "italic", "underline", "list"]

const AnnouncementBody = () => {
  return (
    <>
      <div>Announcement Body</div>
      <Form.Item
        name="body"
        rules={[{ required: true, message: "Please enter an announcement" }]}
      >
        <ReactQuill
          formats={formats}
          modules={modules}
          className="react-quill-style"
        />
      </Form.Item>
    </>
  )
}

export default AnnouncementBody
