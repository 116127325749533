import { useState } from "react"
import Button from "../styled/Button.styled"
import AnnouncementModal from "./AnnouncementModal"

const AnnouncementButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleModal = () => setIsModalOpen((val) => !val)

  return (
    <>
      <Button type="button" onClick={toggleModal}>
        + Add Announcement
      </Button>
      <AnnouncementModal open={isModalOpen} toggleModal={toggleModal} />
    </>
  )
}

export default AnnouncementButton
