import { useState } from "react"
import { DocumentNode, useMutation, useSubscription } from "@apollo/client"
import { UploadProps, Upload, Alert } from "antd"
import { UploadChangeParam } from "antd/es/upload"
import { InboxOutlined } from "@ant-design/icons"

import { xlsxFileMimeType } from "../constants/general"
import Spinner from "./Spinner"

const { Dragger } = Upload

type Props = {
  /** The mutation for uploading the file */
  uploaderMutation: DocumentNode
  /** The subscription for listening changes afte the file upload */
  uploaderSubscription: DocumentNode
  /** The response key on the subscription response
   * It is expected that this key has an object with
   * `type` and `message` properties
   */
  responseKey: string
}

/** This uploader component is used at for uploading a file
 * and listening for the subscription messages while file
 * is being processed
 */
const SubscriptionFileUploader = ({
  uploaderMutation,
  uploaderSubscription,
  responseKey,
}: Props) => {
  const [beforeUploadError, setBeforeUploadError] = useState<string>("")
  const [showSubscriptionMessage, setShowSubscriptionMessage] =
    useState<boolean>(false)

  const [upload, { loading: uploading }] = useMutation(uploaderMutation)

  const { data: subscriptionResponse } = useSubscription(uploaderSubscription)

  const uploadProps: UploadProps = {
    name: "file",
    maxCount: 1,
    accept: xlsxFileMimeType,
    beforeUpload: () => false,
    onChange: (file: UploadChangeParam) => {
      if (validateFile(file)) {
        setShowSubscriptionMessage(() => true)
        uploadFile(file)
      }
    },
  }

  const validateFile = (file: UploadChangeParam): boolean | void => {
    setShowSubscriptionMessage(() => false)
    // Check if file was removed
    if (file.file.status === "removed") {
      return setBeforeUploadError("")
    }
    // Check for file size
    const fileSizeGreaterThanTen: boolean =
      file?.file && file.file.size ? file.file.size / 1024 / 1024 > 10 : false
    if (fileSizeGreaterThanTen) {
      return setBeforeUploadError("Please add a file less than 10 MB")
    }
    // Check for file type
    if (file.file.type !== xlsxFileMimeType) {
      return setBeforeUploadError("Please add .xlsx file only")
    }
    setBeforeUploadError("")
    return true
  }

  const uploadFile = (file: UploadChangeParam): void => {
    upload({
      variables: {
        file: file.file,
      },
    })
  }

  return (
    <>
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined style={{ color: "black" }} />
        </p>
        <p className="ant-upload-text">Upload a file from your computer</p>
      </Dragger>
      <div className="mb-3"></div> {/* Space for Loader and Error*/}
      <div className="col">{uploading && <Spinner />}</div>
      {!!beforeUploadError && (
        <Alert type="error" message={beforeUploadError} />
      )}
      {subscriptionResponse && showSubscriptionMessage && (
        <Alert
          type={subscriptionResponse[responseKey].type}
          message={subscriptionResponse[responseKey].message}
        />
      )}
    </>
  )
}

export default SubscriptionFileUploader
