import { useState, useEffect } from "react"
import { useAppSelector } from "../redux/hooks"

export const useAuthStatus = () => {
  const loggedIn = useAppSelector((state) => state.auth.loggedIn)
  const [checkingStatus, setCheckingStatus] = useState(true)

  useEffect(() => {
    setCheckingStatus(false)
  }, [])

  return { loggedIn, checkingStatus }
}
