import { Form, Input } from "antd"
import { validateLeadingTrailingSpaces } from "../../utils/formUtils"

type Props = { disabled?: boolean }

const LegalLastName = ({ disabled }: Props) => {
  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: "Please enter your legal last name",
        },
        validateLeadingTrailingSpaces(),
      ]}
      name="lastName"
      label="Legal Last Name"
    >
      <Input autoComplete="new-password" disabled={disabled} />
    </Form.Item>
  )
}

export default LegalLastName
