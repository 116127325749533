import { useNavigate } from "react-router-dom"
import globals from "../../constants/globals"
import { CommonPaths, ConsumerPaths } from "../../constants/paths"
import Button, { SecondaryButton } from "../styled/Button.styled"
import EnrollmentHeader from "./EnrollmentHeader"
import heroImage from "../../assets/images/HeaderImage.jpg"

const { PROGRAM_NAME } = globals
const { CLAIM_SALE_PATH } = ConsumerPaths
const { CURRENT_INCENTIVES_PATH } = CommonPaths

const EnrollmentSuccess = () => {
  return (
    <>
      <EnrollmentHeader>
        <img src={heroImage} alt={PROGRAM_NAME} />
        <div className="row justify-content-center">
          <div className="col-8">
            <h4>{PROGRAM_NAME}</h4>
            <h1>You Have Been Enrolled!</h1>
          </div>
        </div>
      </EnrollmentHeader>
      <NavigationLinks />
    </>
  )
}

const NavigationLinks = () => {
  const navigate = useNavigate()

  const goToClaimForm = () => navigate(CLAIM_SALE_PATH)
  const goToProductIncentive = () => navigate(CURRENT_INCENTIVES_PATH)

  return (
    <div className="container mx-auto row justify-content-center mb-5">
      <div className="col-lg-8 col-11 px-3 py-4 p-md-5 border">
        <div className="row justify-content-center gap-3 gap-md-0">
          <div className="col-12 text-center mb-4">
            You can now view the current incentives and start making sales
            claims to earn your rewards onto a Prepaid Visa Reward Card.
          </div>
          <div className="col-md-6">
            <Button type="button" onClick={goToClaimForm}>
              Make a Sales Claim
            </Button>
          </div>
          <div className="col-md-6">
            <SecondaryButton type="button" onClick={goToProductIncentive}>
              View Current Incentives
            </SecondaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnrollmentSuccess
