import { Link } from "./CustomLink"
import { AdminPaths } from "../../constants/paths"
import { AdminPermissions } from "../../constants/permissions"
import DropdownNavbar from "./DropdownNavbar"

const { VIEW_USERS_LIST, VIEW_ENROLLMENT_LIST } = AdminPermissions
const { USERS_LIST_PATH, ENROLLMENT_LIST_PATH } = AdminPaths

const items = [
  {
    label: <Link to={USERS_LIST_PATH}>Administrators</Link>,
    permission: VIEW_USERS_LIST,
    key: "1",
  },
  {
    label: <Link to={ENROLLMENT_LIST_PATH}>Participants</Link>,
    permission: VIEW_ENROLLMENT_LIST,
    key: "2",
  },
]

type UsersNavItemsProps = {
  toggleCollapse: () => void
}

const UsersNavItems = ({ toggleCollapse }: UsersNavItemsProps) => {
  return (
    <DropdownNavbar
      label="Users"
      items={items}
      toggleCollapse={toggleCollapse}
    />
  )
}

export default UsersNavItems
