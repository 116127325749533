import { Popover, Typography } from "antd"
import styled from "styled-components"
import { LinkButton } from "./styled/Button.styled"
import { useAppDispatch } from "../redux/hooks"
import { setDocUrl } from "../redux/ui/uiSlice"

type Props = {
  urls: string[]
  label?: string
  title?: string
}

const DownloadPopover = ({ urls, label = "Download", title }: Props) => {
  return (
    <Popover content={<Content urls={urls} title={title} />}>
      <span className="custom-link">{label}</span>
    </Popover>
  )
}

const Content = ({ urls, title = "Click to Download" }: Props) => {
  const dispatch = useAppDispatch()

  function onClick(url: string) {
    dispatch(setDocUrl(url))
  }
  return (
    <Container>
      <div className="popover-title">{title}</div>
      {urls.map((url,i) => (
        <LinkButton type="button" onClick={() => onClick(url)} key={i}>
          <Typography.Paragraph ellipsis={true}>
            {url.split(".com/")[1].split("?")[0]}
          </Typography.Paragraph>
        </LinkButton>
      ))}
    </Container>
  )
}

const Container = styled.div`
  width: 250px;
  a {
    display: block;
  }
  .popover-title {
    text-align: center;
    background-color: #eee;
    padding: 5px 0;
    border-radius: 5px;
    font-weight: 700;
    margin-bottom: 1rem;
  }
`

export default DownloadPopover
