import { BrowserRouter } from "react-router-dom"
import AdminRoutes from "./routes/AdminRoutes"
import ConsumerRoutes from "./routes/ConsumerRoutes"
import globals from "./constants/globals"
import useGetMyPermissions from "./hooks/useGetMyPermissions"
import GlobalStyles from "./components/styled/GlobalStyles"
import FullScreenSpinner from "./components/styled/FullScreenSpinner.styled"
import ScrollToTop from "./components/Layout/ScrollToTop"
import { useAuthStatus } from "./hooks/useAuthStatus"
import AutoLogout from "./utils/AutoSignout"
import GetRefreshToken from "./utils/GetRefreshToken"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "./components/ErrorFallback"
import { MediaViewer } from "./components/MediaViewer/MediaViewer"

const App = () => {
  const { loading } = useGetMyPermissions()
  const { loggedIn } = useAuthStatus()

  if (loading) {
    return <FullScreenSpinner />
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter>
        {/* Scrolls To Top on Location Change */}
        <ScrollToTop />
        <GlobalStyles />
        {loggedIn && <GetRefreshToken />}
        {loggedIn && <AutoLogout />}
        {globals.IS_ADMIN ? <AdminRoutes /> : <ConsumerRoutes />}
        <MediaViewer />
      </BrowserRouter>
    </ErrorBoundary>
  )
}

export default App
