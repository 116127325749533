import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { INote } from "../../features/ClaimNotes/types"
import dayjs from "dayjs"
import { dateFormatForBackend } from "../../constants/general"
import { ArgsProps } from "antd/es/message"

type IState = {
  verifiedPhones: string[]
  selectedClaimId: string
  selectedNote?: INote
  showDashboard?: boolean
  startDate?: string
  endDate?: string
  promotionName?: string
  alert?: ArgsProps
  /** For storing details about MediaViewer modal */
  docUrl: string
  /** For setting the aiStatusId for modal*/
  aiStatusId?: string
}

const initialState: IState = {
  /** 1. State for storing verified phone numbers for some time
   * in order to avoid sending OTP again for same number
   */
  verifiedPhones: [],
  //2.
  selectedClaimId: "",
  selectedNote: undefined,
  showDashboard: false,
  startDate: dayjs().startOf("month").format(dateFormatForBackend),
  endDate: dayjs().endOf("month").format(dateFormatForBackend),
  promotionName: undefined,
  alert: undefined,
  docUrl: "",
  aiStatusId: undefined,
}

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    resetUiState: () => {
      return initialState
    },
    // 1.
    setVerifiedPhones: (state, { payload }: PayloadAction<string[]>) => {
      state.verifiedPhones = payload
    },
    setSelectedClaimId: (state, { payload }: PayloadAction<string>) => {
      state.selectedClaimId = payload
    },
    setSelectedNote: (state, { payload }: PayloadAction<INote | undefined>) => {
      state.selectedNote = payload
    },
    setStartDate: (state, { payload }) => {
      state.startDate = payload
    },
    setEndDate: (state, { payload }) => {
      state.endDate = payload
    },
    setShowDashboard: (state, { payload }) => {
      state.showDashboard = payload
    },
    setPromotionName: (state, { payload }) => {
      state.promotionName = payload
    },
    setAlertState: (state, { payload }: PayloadAction<ArgsProps | string>) => {
      state.alert =
        typeof payload === "string"
          ? { type: "error", content: payload }
          : payload
    },
    setDocUrl: (state, { payload }: PayloadAction<string>) => {
      state.docUrl = payload
    },
    setAiStatusId: (state, { payload }) => {
      state.aiStatusId = payload
    },
  },
})

export const {
  setVerifiedPhones,
  resetUiState,
  setSelectedClaimId,
  setSelectedNote,
  setStartDate,
  setEndDate,
  setShowDashboard,
  setPromotionName,
  setAlertState,
  setDocUrl,
  setAiStatusId,
} = uiSlice.actions

export default uiSlice.reducer
