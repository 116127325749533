import SubscriptionFileUploader from "../../../components/SubscriptionFileUploader"
import Container from "../../../components/styled/Container.styled"
import { Heading } from "../../../components/styled/Heading"
import { PROMOTIONS_UPLOADER } from "../../../graphql/mutations"
import { PROMOTIONS_UPLOAD_SUBSCRIPTION } from "../../../graphql/subscriptions"

import { SampleFile } from "./SampleFile"

const PromotionsUploader = () => {
  return (
    <Container>
      <div className="row justify-content-center">
        <div className="col-10 col-md-8">
          <Heading heading="Upload Promotions" render={<SampleFile />} />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-10 col-md-8">
          <SubscriptionFileUploader
            uploaderMutation={PROMOTIONS_UPLOADER}
            uploaderSubscription={PROMOTIONS_UPLOAD_SUBSCRIPTION}
            responseKey="promotionFileUpdateStatus"
          />
        </div>
      </div>
    </Container>
  )
}

export default PromotionsUploader
