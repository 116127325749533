import { Form, Input } from "antd"
import { validateLeadingTrailingSpaces } from "../../utils/formUtils"

const City = () => {
  return (
    <Form.Item
      rules={[
        { required: true, message: "Please enter a city name" },
        validateLeadingTrailingSpaces(),
      ]}
      name="city"
      label="City"
    >
      <Input autoComplete="new-password" />
    </Form.Item>
  )
}

export default City
