import { createGlobalStyle } from "styled-components"
import globals from "../../constants/globals"

const { PRIMARY_COLOR, SECONDARY_COLOR } = globals

const GlobalStyles = createGlobalStyle`
  .cursor-pointer {
    cursor: pointer
  }

  .cursor-default {
    cursor: default;
  }
  
  .nav-item {
    margin: 0 3px;
    &:hover,
    .active {
      margin-bottom: -2px;
      border-bottom: 2px solid ${PRIMARY_COLOR};
      color: ${PRIMARY_COLOR};
    }
    a {
      &:hover, &:active, &:focus, .active {
        text-decoration: none;
      }
    }
    div {
      padding-left: 5px;
    }
    @media (max-width: 992px) {
      a {
        padding-left: 10px;
      }
    }
  }

  .dropdown-navbar {
    width: 250px;
    li a {
      text-decoration: none;
      &:hover, &:active, &:focus, .active {
        color: #fff !important;
        text-decoration: none;
      }
    }
  }

  .ant-menu-item-selected {
    color: #000 !important;
  }

  body {
    font-family: 'Roboto', sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
  }

  .custom-link, a {
    color: ${PRIMARY_COLOR};
    text-decoration: underline;
    cursor: pointer;
    &:hover, &:focus {
      color: ${SECONDARY_COLOR};
      text-decoration: underline;
    }
    /** This is mainly for the DownloadPopover component
     * which has a nested Typography antd component 
     */
    div {
      color: ${SECONDARY_COLOR};
      &:hover, &:focus {
        color: ${SECONDARY_COLOR};
      }
    }
  }

  .custom-bg {
    background-color: ${PRIMARY_COLOR};
  }

  .participant-preview-text {
    color: #aaa;
    background-color: #fff;
    translate: 0px -30px;
    width: 14%;
    min-width: 300px;
    margin: auto;
    text-align: center;
  }

  .participant-preview-border {
    color: #fff;
    border-bottom: 2px dashed #aaa;
  }

  /* Edit Claim Modal Styles */
  .edit-claim-modal {
    min-width: 70%;
    @media screen and (max-width: 992px) {
      min-width: 90%;
    }
  }

  /* Announcement Modal Styles */
  .announcement-modal {
    min-width: 60%;
    @media screen and (max-width: 992px) {
      min-width: 90%;
    }
  }
  .react-quill-style {
    .ql-editor {
      height: 180px;
    }
    .ql-toolbar.ql-snow {
      border-radius: 4px 4px 0px 0px;
    }
    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-radius: 0px 0px 8px 8px;
    }
  }

  .close-icon {
    cursor: pointer;
    font-size: 2rem;
    margin-top: 8px;
    color: #000;
  }

  /** Layout */
  #root {
    height: 100%;
  }

  .vh-250 {
    min-height: calc(100vh - 250px);
  }

  .vh-200 {
    min-height: calc(100vh - 200px);
  }

  .vh-150 {
    min-height: calc(100vh - 150px);
  }

 .user-stats {
  background-color: #fff;
  color: ${PRIMARY_COLOR};
 }

 .fs-14 {
    font-size: 14px;
  }
`

export default GlobalStyles
