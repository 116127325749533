import { gql } from "@apollo/client"

// Admin Side
const DEALER_UPLOAD_SUBSCRIPTION = gql`
  subscription DealerFileUpdateStatus {
    dealerFileUpdateStatus {
      message
      type
    }
  }
`

const PRODUCT_UPLOAD_SUBSCRIPTION = gql`
  subscription ProductFileUpdateStatus {
    productFileUpdateStatus {
      message
      type
    }
  }
`

const PARTICIPANT_UPLOAD_SUBSCRIPTION = gql`
  subscription ParticipantFileUpdateStatus {
    participantFileUpdateStatus {
      message
      type
    }
  }
`

const PROMOTIONS_UPLOAD_SUBSCRIPTION = gql`
	subscription PromotionFileUpdateStatus {
		promotionFileUpdateStatus {
			type
			message
		}
	}
`

export {
  DEALER_UPLOAD_SUBSCRIPTION,
  PRODUCT_UPLOAD_SUBSCRIPTION,
  PARTICIPANT_UPLOAD_SUBSCRIPTION,
  PROMOTIONS_UPLOAD_SUBSCRIPTION
}
