const ContactUsSuccess = () => {
  return (
    <div>
      <h5>
        <strong>Message Sent</strong>
      </h5>
      <p>
        Thank you for contacting customer service. A representative will reach
        out to you within 48 hours.
      </p>
    </div>
  )
}

export default ContactUsSuccess
