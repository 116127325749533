import { Form, Input } from "antd"

const ZIP = () => {
  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: "Please enter a ZIP code",
        },
        {
          pattern: /^[0-9]{5}$/,
          message: "ZIP code must be 5 digits",
        },
      ]}
      name="zip"
      label="ZIP"
    >
      <Input />
    </Form.Item>
  )
}

export default ZIP
