import { useEffect } from "react"

import globals from "../../constants/globals"
import Footer from "../Layout/Footer"
import Header from "../Layout/Header"
import Container from "../styled/Container.styled"

const { UNKNOWN_USER_REDIRECT_URL } = globals

function UnknownCustomerRedirect() {
  useEffect(() => {
    // Timeout to let the user read the message
    const timer = setTimeout(() => {
      window.location.href = UNKNOWN_USER_REDIRECT_URL as string
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <Header showNone />
      <Container>
        <div className="row justify-content-center py-5">
          <div className="col-xl-4 col-lg-6 col-md-8 col-10 border p-5">
            You are being redirected to the K Dealer site. From there you can
            login and click the Kia Kash Vault 2023 logo to be taken to your
            participant account.
          </div>
        </div>
      </Container>
      <Footer />
    </>
  )
}

export { UnknownCustomerRedirect }
