import Layout from "../components/Layout/Layout"
import SignIn from "../pages/SignIn"
import SignOut from "../pages/SignOut"
import commonRoutes from "./CommonRoutes"
import AuthorisedRoute from "../components/AuthorisedRoute"
import { useRoutes } from "react-router-dom"
import { lazy, useMemo } from "react"
import { AdminPermissions, CommonPermissions } from "../constants/permissions"
import type { IRoute, IParentRoute } from "../types/route"
import { AdminPaths, CommonPaths } from "../constants/paths"
import useGetMyPermissions from "../hooks/useGetMyPermissions"
import UpdatePassword from "../pages/UpdatePassword"
import NotFound from "../pages/NotFound"
import ForgotPassword from "../pages/ForgotPassword"
import Users from "../pages/admin/Users"
import InvoiceOrder from "../pages/admin/InvoiceOrder"
import ChangeAddressReport from "../pages/admin/ChangeAddressReport"
import ProductUploader from "../pages/admin/ProductUploader"
import ParticipantUploader from "../pages/admin/ParticipantUploader"
import Home from "../pages/Home"
import { EditVault } from "../pages/admin/EditVault"
import PromotionsUploader from '../pages/admin/PromotionUploader/PromotionsUploader'

const EnrollmentList = lazy(() => import("../pages/admin/EnrollmentList"))
const DealerUpload = lazy(() => import("../pages/admin/DealerUpload"))
const Quizzes = lazy(() => import("../pages/admin/Quizzes"))
const ProgramCommunications = lazy(
  () => import("../pages/admin/ProgramCommunications")
)
const Claims = lazy(() => import("../pages/admin/Claims"))
const ClaimsApproval = lazy(() => import("../pages/admin/ClaimsApproval"))
const ClaimReport = lazy(() => import("../pages/admin/ClaimReport"))
const EnrollmentReport = lazy(() => import("../pages/admin/EnrollmentReport"))
const AddUser = lazy(() => import("../pages/admin/AddUser"))
const EditClaim = lazy(() => import("../pages/admin/EditClaim"))
const ContactUs = lazy(() => import("../pages/ContactUs"))
const Dashboard = lazy(() => import("../pages/admin/Dashboard"))

const {
  VIEW_ADMIN_HOME,
  VIEW_ENROLLMENT_LIST,
  VIEW_DEALER_UPLOADER,
  VIEW_QUIZZES,
  VIEW_PROGRAM_COMMUNICATIONS,
  VIEW_CLAIMS,
  VIEW_CLAIM_REPORT,
  VIEW_ADD_USER,
  VIEW_ENROLLMENT_REPORT,
  VIEW_CLAIM_APPROVAL,
  VIEW_EDIT_CLAIM,
  VIEW_USERS_LIST,
  VIEW_INVOICE_ORDER,
  VIEW_CHANGE_ADDRESS_REPORT,
  VIEW_PRODUCT_UPLOADER,
  VIEW_ANNOUNCEMENT,
  VIEW_PARTICIPANT_UPLOADER,
  VIEW_EDIT_VAULT_AMOUNT,
  VIEW_PROMOTIONS_UPLOADER
} = AdminPermissions

const {
  DEALER_UPLOADER_PATH,
  ENROLLMENT_LIST_PATH,
  QUIZZES_PATH,
  PROGRAM_COMMUNICATIONS_PATH,
  CLAIMS_PATH,
  CLAIM_REPORT_PATH,
  ADD_USER_PATH,
  ENROLLMENT_REPORT_PATH,
  CLAIM_APPROVAL_PATH,
  EDIT_CLAIM_PATH,
  USERS_LIST_PATH,
  INVOICE_ORDER_PATH,
  CHANGE_ADDRESS_REPORT_PATH,
  PRODUCT_UPLOADER_PATH,
  ANNOUNCEMENT_PATH,
  PARTICIPANT_UPLOADER_PATH,
  PROMOTIONS_UPLOADER_PATH,
  EDIT_VAULT_PATH
} = AdminPaths

const {
  SIGNIN_PATH,
  SIGNOUT_PATH,
  UPDATE_PASSWORD_PATH,
  HOME_PATH,
  FORGOT_PASSWORD_PATH,
  CONTACTUS_PATH,
} = CommonPaths

const { VIEW_CONTACTUS } = CommonPermissions

const AdminRoutes = () => {
  const { permissions } = useGetMyPermissions()

  const adminPrivateRoutes: IRoute[] = [
    {
      element: <Home />,
      path: ANNOUNCEMENT_PATH,
      permissionCode: VIEW_ANNOUNCEMENT,
    },
    {
      element: <EnrollmentList />,
      path: ENROLLMENT_LIST_PATH,
      permissionCode: VIEW_ENROLLMENT_LIST,
    },
    {
      element: <DealerUpload />,
      path: DEALER_UPLOADER_PATH,
      permissionCode: VIEW_DEALER_UPLOADER,
    },
    {
      element: <ProductUploader />,
      path: PRODUCT_UPLOADER_PATH,
      permissionCode: VIEW_PRODUCT_UPLOADER,
    },
    {
      element: <ParticipantUploader />,
      path: PARTICIPANT_UPLOADER_PATH,
      permissionCode: VIEW_PARTICIPANT_UPLOADER,
    },
    {
			element: <PromotionsUploader />,
			path: PROMOTIONS_UPLOADER_PATH,
			permissionCode: VIEW_PROMOTIONS_UPLOADER,
		},
    {
      element: <Quizzes />,
      path: QUIZZES_PATH,
      permissionCode: VIEW_QUIZZES,
    },
    {
      element: <ProgramCommunications />,
      path: PROGRAM_COMMUNICATIONS_PATH,
      permissionCode: VIEW_PROGRAM_COMMUNICATIONS,
    },
    {
      element: <Claims />,
      path: CLAIMS_PATH,
      permissionCode: VIEW_CLAIMS,
    },
    {
      element: <ClaimsApproval />,
      path: CLAIM_APPROVAL_PATH,
      permissionCode: VIEW_CLAIM_APPROVAL,
    },
    {
      element: <ClaimReport />,
      path: CLAIM_REPORT_PATH,
      permissionCode: VIEW_CLAIM_REPORT,
    },
    {
      element: <EnrollmentReport />,
      path: ENROLLMENT_REPORT_PATH,
      permissionCode: VIEW_ENROLLMENT_REPORT,
    },
    {
      element: <ChangeAddressReport />,
      path: CHANGE_ADDRESS_REPORT_PATH,
      permissionCode: VIEW_CHANGE_ADDRESS_REPORT,
    },
    {
      element: <Dashboard />,
      path: HOME_PATH,
      permissionCode: VIEW_ADMIN_HOME,
    },
    {
      element: <AddUser />,
      path: ADD_USER_PATH,
      permissionCode: VIEW_ADD_USER,
    },
    {
      element: <Users />,
      path: USERS_LIST_PATH,
      permissionCode: VIEW_USERS_LIST,
    },
    {
      element: <EditClaim />,
      path: EDIT_CLAIM_PATH,
      permissionCode: VIEW_EDIT_CLAIM,
    },
    {
      element: <InvoiceOrder />,
      path: INVOICE_ORDER_PATH,
      permissionCode: VIEW_INVOICE_ORDER,
    },
    {
      element: <ContactUs />,
      path: CONTACTUS_PATH,
      permissionCode: VIEW_CONTACTUS,
    },
    {
      element: <EditVault />,
      path: EDIT_VAULT_PATH,
      permissionCode: VIEW_EDIT_VAULT_AMOUNT,
    },
    ...commonRoutes,
  ]

  const filteredAdminPrivateRoutes = adminPrivateRoutes.map((route: IRoute) =>
    permissions.includes(route.permissionCode)
      ? route
      : {
          ...route,
          element: <NotFound />,
        }
  )

  const adminRoutes: IParentRoute = useMemo(
    () => [
      {
        element: <Layout />,
        children: [
          {
            element: <AuthorisedRoute />,
            children: filteredAdminPrivateRoutes,
          },
        ],
      },
      { element: <SignOut />, path: SIGNOUT_PATH },
      { element: <ForgotPassword />, path: FORGOT_PASSWORD_PATH },
      { element: <UpdatePassword />, path: UPDATE_PASSWORD_PATH },
      { element: <SignIn />, path: SIGNIN_PATH },
      { element: <NotFound />, path: "*" },
    ],
    [filteredAdminPrivateRoutes]
  )

  return useRoutes(adminRoutes)
}

export default AdminRoutes
