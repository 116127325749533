import { Alert, Form, Input } from "antd"
import Button from "../components/styled/Button.styled"
import Container from "../components/styled/Container.styled"
import { Link, useNavigate } from "react-router-dom"
import Header from "../components/Layout/Header"
import Footer from "../components/Layout/Footer"
import { useEffect } from "react"
import { useAuthStatus } from "../hooks/useAuthStatus"
import { PASSWORD_RESET } from "../graphql/mutations"
import { useMutation } from "@apollo/client"
import globals from "../constants/globals"
import Spinner from "../components/Spinner"
import { CommonPaths, ConsumerPaths } from "../constants/paths"

const { IS_ADMIN } = globals

const ForgotPassword = () => {
  const { loggedIn } = useAuthStatus()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [passwordReset, { data, loading, error, reset }] =
    useMutation(PASSWORD_RESET)

  useEffect(() => {
    if (loggedIn) {
      navigate("/")
    }
  }, [loggedIn, navigate])

  const onFinish = (value: any) => {
    reset()
    passwordReset({
      variables: {
        userEmail: value.email,
        signingInFromConsumerOrAdmin: IS_ADMIN ? "admin" : "consumer",
      },
    })
  }

  return (
    <>
      <Header showNone />

      <Container>
        <div className="row justify-content-center py-4">
          <h1 className="text-center text-uppercase fw-bold">Reset Password</h1>
        </div>
        <div className="row justify-content-center">
          {data ? (
            <div className="col-lg-6 col-11">
              <SuccessMessage />
            </div>
          ) : (
            <div className="col-lg-5 col-11 px-3 py-4 p-md-5 border">
              <Form
                layout="vertical"
                onFinish={onFinish}
                form={form}
                requiredMark={false}
              >
                <div className="row">
                  <div className="col-12">
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email",
                        },
                        {
                          type: "email",
                          message: "Invalid email address.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {error && <Alert type="error" message={error.message} />}
                    {loading && <Spinner />}
                  </div>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="col-12">
                    <Button type="submit">Submit</Button>
                  </div>
                </div>
              </Form>

              {/* Links in bottom */}
              <div className="row pt-4">
                <div className="col-6 text-start">
                  {!IS_ADMIN && (
                    <Link
                      to={ConsumerPaths.ENROLLMENT_FORM_PATH}
                      className="custom-link"
                    >
                      Enroll Now
                    </Link>
                  )}
                </div>
                <div className="col-6 text-end">
                  <Link to={CommonPaths.SIGNIN_PATH} className="custom-link">
                    Sign In
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>

      <Footer />
    </>
  )
}

const SuccessMessage = () => {
  return (
    <div className="text-center">
     An email has been sent to you to reset your password.
    </div>
  )
}

export default ForgotPassword
