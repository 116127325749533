import { ColumnsType } from "antd/es/table"

import { IAuditNote } from "./types"
import { formatDateWithTime } from "../../../utils/date"

const auditNotesColumns: ColumnsType<IAuditNote> = [
  { title: "Name", dataIndex: "userName" },
  {
    title: "Creation Date",
    dataIndex: "createdAt",
    render: (value) => formatDateWithTime(value),
  },
  {
    title: "Old Value",
    dataIndex: "oldValue",
    render: (value, record) => {
      return `${fieldNameKeys[record.fieldName]}: ${value}`
    },
  },
  {
    title: "New Value",
    dataIndex: "newValue",
    render: (value, record) => {
      return `${fieldNameKeys[record.fieldName]}: ${value}`
    },
  },
]

const fieldNameKeys = {
    productId: "Product ID",
    productIdManual: "Product ID",
    productName: "Product Name",
    productDescription: "Product Description",
    dateOfSale: "Date Of Sale",
    enrolmentId: "Enrolment ID",
    firstName: "First Name",
    lastName: "Last Name",
    enrolmentEmail: "Enrollment Email",
    dealerId: "Dealer ID",
    dealerName: "Dealer Name",
    sku: "SKU",
    productPurchased: "Product Purchased",
    brand: "Brand",
    quantity: "Quantity",
    awardValue: "Award Amount",
    bonusValue: "Bonus Amount",
    retroBonusForOtherClaims: "Retro bonus for other claims",
    status: "Status",
    statusUpdatedAt: "Status Updated Date",
    approvalDate: "Approval Date",
    orderId: "Order ID",
    claimDocumentUrl: "Claim Document URL",
    declinedReasons: "Declined Reason",
    isProcessed: "Is Processed",
    isAwardOverridden: "Is Award Overridden",
    isBonusOverridden: "Is Bonus Overridden",
    bonusCriteria: "Bonus Criteria",
    isApprovedEmailSent: "Is Approved Email Sent",
    isDeclinedEmailSent: "Is Declined Email Sent",
    isOrderAssigned: "Is Order Assigned",
    assignedOrder: "Assigned Order",
    approvedOrDeclinedBy: "Approved or Declined By",
    approvedOrDeclinedByName: "Approved or Declined By Name",
  }

  export { auditNotesColumns, fieldNameKeys }