enum AdminPermissions {
  VIEW_ENROLLMENT_LIST = "view_enrollment_list",
  VIEW_DEALER_UPLOADER = "view_dealer_uploader",
  VIEW_PRODUCT_UPLOADER = "view_product_uploader",
  VIEW_PARTICIPANT_UPLOADER = "view_participant_uploader",
  VIEW_ADMIN_HOME = "view_admin_home",
  VIEW_QUIZZES = "view_quizzes",
  VIEW_PROGRAM_COMMUNICATIONS = "view_program_communications",
  VIEW_CLAIMS = "view_claims",
  VIEW_CLAIM_REPORT = "view_claim_report",
  VIEW_CHANGE_ADDRESS_REPORT = "view_change_address_report",
  VIEW_ENROLLMENT_REPORT = "view_enrollment_report",
  VIEW_ADD_INCENTIVE = "view_add_incentive",
  VIEW_ADD_USER = "view_add_user",
  VIEW_CLAIM_APPROVAL = "view_claim_approval",
  VIEW_EDIT_CLAIM = "view_edit_claim",
  VIEW_USERS_LIST = "view_users_list",
  VIEW_SEND_TEXT = "view_send_text",
  VIEW_SEND_EMAIL = "view_send_email",
  VIEW_INVOICE_ORDER = "view_invoice_order",
  VIEW_ANNOUNCEMENT = "view_announcement",
  VIEW_PROMOTIONS_UPLOADER = 'view_promotions_uploader',

  // Edit Claim Form
  EDIT_CLAIM_RECEIPT = "edit_claim_receipt",
  EDIT_CLAIM_MODEL = "edit_claim_model",
  EDIT_CLAIM_DATE_OF_SALE = "edit_claim_date_of_sale",
  EDIT_CLAIM_ORDERID = "edit_claim_orderid",
  EDIT_CLAIM_QUANTITY = "edit_claim_quantity",
  EDIT_CLAIM_AWARD = "edit_claim_award",
  EDIT_CLAIM_BONUS = "edit_claim_bonus",
  EDIT_CLAIM_STATUS = "edit_claim_status",

  // Home Page Admin Permissions
  ADD_HOME_ANNOUNCEMENT = "add_home_announcement",
  DELETE_HOME_ANNOUNCEMENT = "delete_home_announcement",
  UPDATE_HOME_ANNOUNCEMENT = "update_home_announcement",
  UPDATE_HOME_PROGRAM_HEADER = "update_home_program_header",

  // Current Incentives Controls
  VIEW_CURRENT_INCENTIVES_REPORT = "view_incentives_report",
  DELETE_CURRENT_INCENTIVE = "delete_current_incentive",
  UPDATE_CURRENT_INCENTIVE = "update_current_incentive",

  // Edit User
  UPDATE_USER_ROLE = "update_user_role",
  UPDATE_USER_STATUS = "update_user_status",
  UPDATE_USER_EMAIL = "update_user_email",

  // Uploader Active File Permissiosn
  VIEW_ACTIVE_DEALER_FILE = "view_active_dealer_file",
  VIEW_ACTIVE_PARTICIPANT_FILE = "view_active_participant_file",

  // Claim Approval Permissions
  VIEW_CLAIM_APPROVAL_PENDING = "view_claim_approval_pending",
  VIEW_CLAIM_APPROVAL_APPROVED = "view_claim_approval_approved",
  VIEW_CLAIM_APPROVAL_DECLINED = "view_claim_approval_declined",
  VIEW_CLAIM_APPROVAL_FUNDED = "view_claim_approval_funded",

  // Claim Notes Permission
  VIEW_CLAIM_NOTES = "view_claim_notes",
  ADD_NOTES = "add_claim_notes",
  AUDIT_NOTES = "audit_claim_notes",

  // Permission for Edit Vault Amount 
  VIEW_EDIT_VAULT_AMOUNT = "view_edit_vault_amount",
}

enum ConsumerPermissions {
  VIEW_CONSUMER_HOME = "view_consumer_home",
  VIEW_ENROLLMENT_FORM = "view_enrollment_form",
  VIEW_CLAIM_SALE = "view_claim_sale",
  VIEW_MY_ACCOUNT = "view_my_account",
  VIEW_INBOX = "view_inbox",
  VIEW_TEXT_MESSAGES = "view_text_messages",

  // My Account Permissions
  VIEW_MY_CLAIMS = "view_my_claims",
  VIEW_MY_PROFILE = "view_my_profile",
}

enum CommonPermissions {
  VIEW_CONTACTUS = "view_contactus",
  VIEW_CURRENT_INCENTIVES = "view_current_incentives",
  VIEW_RESOURCES = "view_resources",
  VIEW_FUNDING_HISTORY = "view_funding_history",
}

export { AdminPermissions, ConsumerPermissions, CommonPermissions }
