import styled from "styled-components"

const CenterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export default CenterContent
