import { useLazyQuery } from "@apollo/client"
import { useCallback, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { CommonPaths } from "../constants/paths"
import { GET_TOKEN_FROM_REFRESH_TOKEN } from "../graphql/queries"
import { setTokenTimeout } from "../redux/auth/authSlice"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { IGetRefreshTokenResponse } from "../types/graphqlResponse"
import { authClient } from "./apolloClient"
import { getRefreshToken, setAuthToken } from "./auth"

const { SIGNOUT_PATH } = CommonPaths

/**
 * This component is mounted only when the user is logged in.
 * It starts a timer based on the `tokenTimeout` value received on sign in
 * If the user is logged in, and the page is refreshed, then the default tokenTimeout
 * value from redux store(0) is used and the new token is called for using refresh token.
 */
const GetRefreshToken = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const refreshToken = getRefreshToken() as string
  const timeoutRef = useRef<number | undefined>()

  const tokenTimeout = useAppSelector((state) => state.auth.tokenTimeout)

  // Get New Token Mutation
  const [getNewToken] = useLazyQuery<IGetRefreshTokenResponse>(
    GET_TOKEN_FROM_REFRESH_TOKEN,
    {
      // Using different client for refresh token
      // Otherwise, it was sending expired token in request
      client: authClient,
      variables: { refreshToken },
      onCompleted(data) {
        // Setting new token and timeout
        setAuthToken({
          token: data.refreshSessionToken.token,
          refresh_token: refreshToken,
        })
        dispatch(setTokenTimeout(data.refreshSessionToken.tokenTime))
        // Reset Timer when new timer value is set
        resetTimer()
      },
      onError: () => {
        navigate(SIGNOUT_PATH)
      },
    }
  )

  // Timer logic
  const startTimer = useCallback(() => {
    timeoutRef.current = window.setTimeout(() => {
      if (refreshToken) {
        getNewToken()
      }
    }, tokenTimeout)
  }, [getNewToken, tokenTimeout, refreshToken])

  const stopTimer = () => {
    window.clearTimeout(timeoutRef.current)
  }

  const resetTimer = useCallback(() => {
    stopTimer()
    startTimer()
  }, [startTimer])

  // Starting the timer on mount
  useEffect(() => {
    startTimer()
    return stopTimer
  }, [startTimer])

  return <></>
}

export default GetRefreshToken
