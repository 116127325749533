import { PRODUCT_UPLOAD } from "../../graphql/mutations"
import { PRODUCT_UPLOAD_SUBSCRIPTION } from "../../graphql/subscriptions"
import { Heading } from "../../components/styled/Heading"
import Container from "../../components/styled/Container.styled"
import SubscriptionFileUploader from "../../components/SubscriptionFileUploader"

const ProductUploader = () => {
  return (
    <Container>
      <div className="row justify-content-center">
        <div className="col-10 col-md-8">
          <Heading heading="Upload Products" />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-10 col-md-8">
          <SubscriptionFileUploader
            uploaderMutation={PRODUCT_UPLOAD}
            uploaderSubscription={PRODUCT_UPLOAD_SUBSCRIPTION}
            responseKey="productFileUpdateStatus"
          />
        </div>
      </div>
    </Container>
  )
}

export default ProductUploader
