import { Alert, Form } from "antd"
import Button from "../components/styled/Button.styled"
import Container from "../components/styled/Container.styled"
import { Heading } from "../components/styled/Heading"
import { useMutation } from "@apollo/client"
import { UPDATE_PASSWORD } from "../graphql/mutations"
import Spinner from "../components/Spinner"
import { useNavigate, useParams } from "react-router-dom"
import Password from "../components/Form/Password"
import ConfirmPassword from "../components/Form/ConfirmPassword"
import Header from "../components/Layout/Header"
import Footer from "../components/Layout/Footer"
import { useEffect } from "react"
import { useAuthStatus } from "../hooks/useAuthStatus"
import { CommonPaths } from "../constants/paths"

const { HOME_PATH, SIGNIN_PATH } = CommonPaths

const UpdatePassword = () => {
  const { loggedIn } = useAuthStatus()
  const navigate = useNavigate()
  const [updatePassword, { loading, error }] = useMutation(UPDATE_PASSWORD)
  const [form] = Form.useForm()
  const { token } = useParams()

  useEffect(() => {
    if (loggedIn) {
      navigate(HOME_PATH)
    }
  }, [loggedIn, navigate])

  const onFinish = (values: any) => {
    updatePassword({
      variables: {
        input: {
          password: values.pwd,
          token: token,
        },
      },
      onCompleted: () => {
        navigate(SIGNIN_PATH)
      },
    })
  }

  return (
    <>
      <Header />
      <Container>
        <Heading heading="Create Password" />

        <div className="row justify-content-center">
          <div className="col-lg-5 col-11 px-3 py-4 p-md-5 border">
            <Form layout="vertical" onFinish={onFinish} form={form}>
              <div className="row">
                <div className="col-12">
                  <Password form={form} />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <ConfirmPassword />
                </div>
              </div>

              {loading && <Spinner />}

              {error && <Alert type="error" message={error.message} />}

              <div className="row justify-content-center mt-3">
                <div className="col-12 col-md-6">
                  <Button type="submit">Submit</Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default UpdatePassword
