import { Link, useLocation } from "react-router-dom"
import Button from "../styled/Button.styled"
import { ConsumerPaths } from "../../constants/paths"
import useGetMyPermissions from "../../hooks/useGetMyPermissions"
import { ConsumerPermissions } from "../../constants/permissions"

const { CLAIM_SALE_PATH } = ConsumerPaths
const { VIEW_CLAIM_SALE } = ConsumerPermissions

const ClaimSaleButton = () => {
  const { pathname } = useLocation()
  const { permissions } = useGetMyPermissions()

  const isClaimSalePage =
    pathname === CLAIM_SALE_PATH || pathname === `${CLAIM_SALE_PATH}/`

  const showClaimSaleButton =
    permissions.includes(VIEW_CLAIM_SALE) && !isClaimSalePage

  return showClaimSaleButton ? (
    <div className="bg-white d-md-none py-2 sticky-top border-bottom shadow-sm row justify-content-center ">
      <div className="col-6">
        <Link to={CLAIM_SALE_PATH} className="text-black text-decoration-none">
          <Button type="button">Claim a Sale</Button>
        </Link>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ClaimSaleButton
