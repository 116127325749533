import { Form, Input } from "antd"

const AnnouncementTitle = () => {
  return (
    <Form.Item
      name="title"
      label="Announcement Title"
      rules={[{ required: true, message: "Please enter announcement title" }]}
    >
      <Input />
    </Form.Item>
  )
}

export default AnnouncementTitle
