import { useMutation } from "@apollo/client"
import { Form } from "antd"
import { useState } from "react"
import { CREATE_UPDATE_ANNOUNCEMENT } from "../../graphql/mutations"
import { GET_ANNOUNCEMENTS } from "../../graphql/queries"
import { IAnnouncement } from "../../types/graphqlResponse"
import AnnouncementBody from "./AnnouncementBody"
import AnnouncementFooter from "./AnnouncementFooter"
import AnnouncementImage from "./AnnouncementImage"
import AnnouncementTitle from "./AnnouncementTitle"

type AnnouncementFormProps = {
  toggleModal: () => void
  itemToEdit?: IAnnouncement
}

const AnnouncementForm = ({
  toggleModal,
  itemToEdit,
}: AnnouncementFormProps) => {
  const [blockSubmit, setBlockSubmit] = useState(false)

  const [addUpdateAnnouncement, result] = useMutation<any>(
    CREATE_UPDATE_ANNOUNCEMENT
  )

  const onFinish = (values: any) => {
    if (blockSubmit) return

    addUpdateAnnouncement({
      variables: {
        announcement: {
          ...values,
          image: values.image?.file,
          // Send the ID in case of editing the annoucement
          id: itemToEdit ? itemToEdit._id : undefined,
        },
      },
      onCompleted: toggleModal,
      refetchQueries: [
        { query: GET_ANNOUNCEMENTS, variables: { page: 1, limit: 3 } },
        "ListAnnouncementsWithPagination",
      ],
    })
  }

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      requiredMark={false}
      initialValues={{
        title: itemToEdit?.title.trim(),
        body: itemToEdit?.body.trim(),
      }}
    >
      <AnnouncementTitle />
      <AnnouncementBody />
      <AnnouncementImage
        setBlockSubmit={setBlockSubmit}
        itemToEdit={itemToEdit}
      />
      <AnnouncementFooter result={result} />
    </Form>
  )
}

export default AnnouncementForm
