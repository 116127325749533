import { Form, Input } from "antd"
import { validateLeadingTrailingSpaces } from "../../utils/formUtils"

type Props = { disabled?: boolean }

const GoesByName = ({ disabled }: Props) => {
  return (
    <Form.Item
      name="goesByName"
      label="Goes By Name"
      rules={[validateLeadingTrailingSpaces()]}
    >
      <Input autoComplete="new-password" disabled={disabled} />
    </Form.Item>
  )
}

export default GoesByName
