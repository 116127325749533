import dayjs from "dayjs"

// This function takes out value from date picker from ant design
const getDate = (date: any) => {
  return dayjs(date).format("YYYY-MM-DD")
}

// To convert date from "YYYY-MM-DD" to "MM/DD/YYYY"
const getFormattedDate = (date?: string) => {
  if (date) {
    const YYYY = date.split("-")[0]
    const MM = date.split("-")[1]
    const DD = date.split("-")[2]

    return MM + "/" + DD + "/" + YYYY
  }
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

const formatAmount = (amount?: number) => {
  return amount ? formatter.format(amount) : "$0.00"
}

const formatDateWithTime = (dateString: string): string => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const amPm = hours >= 12 ? 'pm' : 'am';

  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedHours = hours < 10 ? `0${hours}` : hours;

  return `${formattedMonth}/${formattedDay}/${year} ${formattedHours}:${minutes} ${amPm}`;
}

/**
 * This function takes in 'h', 'm', 's', or 'ms' and spits out milliseconds
 */
const getMillisecondsFromTokenTime = (time: string): number => {
  if (time.endsWith("h")) {
    const hours = time.split("h")[0]
    return Number(hours) * 60 * 60 * 1000
  } else if (time.endsWith("m")) {
    const mins = time.split("m")[0]
    return Number(mins) * 60 * 1000
  } else if (time.endsWith("ms")) {
    const milliSecs = time.split("ms")[0]
    return Number(milliSecs)
  } else if (time.endsWith("s")) {
    const secs = time.split("s")[0]
    return Number(secs) * 1000
  }
  return 0
}

export { getDate, getFormattedDate, formatAmount, getMillisecondsFromTokenTime, formatDateWithTime }
