import { Image, Layout, Row, Col } from 'antd';
import cardImage from '../../assets/images/bpscardaccount.png'
import cardBalanceimg from'../../assets/images/Card Balance - BPSmyaccount.png'
const { Content } = Layout;
const BalanceContent = () => {
    const styledA = {
        fontSize: '20px',
        textDecoration:'none',
        margin:'5px'
    };
    return (
        <>
            <Layout className="py-5 vh-fill">
                <Content>
                    <Row>
                        <Col>
                            <h5 className="display-6">Please check the URL on the back of your card</h5>
                            <p className="mb-0">Follow the url on the back of your card to activate or check the balance of your Visa card.</p>
                        </Col>
                    </Row>
                    <Row className="mt-3 gap-x-6">
                        <Col lg={{ span: 6 }} className="flex flex-col items-center mx-5">
                            <Image src={cardImage} alt="Card sample" width="250px" className="mb-2" />
                            <p><a href="https://bpscardaccount.com" style={styledA} target="_blank" rel="noopener noreferrer">bpscardaccount.com </a></p>
                        </Col>
                        <Col lg={{ span: 6 }} className="flex flex-col items-center mx-5">
                            <Image src={cardBalanceimg} alt="Card sample" width="260px" className="mb-2" />
                            <p><a href="https://bpsmyaccount.com" style={styledA} target="_blank" rel="noopener noreferrer">bpsmyaccount.com  </a></p>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </>
    );
}

export default BalanceContent;
