import { Link } from "./CustomLink"
import { AdminPaths } from "../../constants/paths"
import { AdminPermissions } from "../../constants/permissions"
import DropdownNavbar from "./DropdownNavbar"

const { CLAIMS_PATH, CLAIM_APPROVAL_PATH } = AdminPaths

const { VIEW_CLAIMS, VIEW_CLAIM_APPROVAL } = AdminPermissions

const items = [
  {
    label: <Link to={CLAIMS_PATH}>Search All Claims</Link>,
    permission: VIEW_CLAIMS,
    key: "1",
  },
  {
    label: <Link to={CLAIM_APPROVAL_PATH}>Approve Pending Claims</Link>,
    permission: VIEW_CLAIM_APPROVAL,
    key: "2",
  },
]

type ClaimsNavItemsProps = {
  toggleCollapse: () => void
}

const ClaimsNavItems = ({ toggleCollapse }: ClaimsNavItemsProps) => {
  return (
    <DropdownNavbar
      items={items}
      label="Claims"
      toggleCollapse={toggleCollapse}
    />
  )
}

export default ClaimsNavItems
