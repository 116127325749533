import { Modal, Tabs } from "antd"

import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { setSelectedClaimId } from "../../redux/ui/uiSlice"
import client from "../../utils/apolloClient"
import NotesForm from "./Form"
import NotesTable from "./Table"
import { Audit } from "./AuditNotes"
import type { Tab } from "rc-tabs/lib/interface"
import { IPermission } from "../../types/permission"
import { AdminPermissions } from "../../constants/permissions"
import useGetMyPermissions from "../../hooks/useGetMyPermissions"
const { VIEW_CLAIM_NOTES, ADD_NOTES, AUDIT_NOTES } = AdminPermissions

type TabItem = Tab & {
  permissionCode: IPermission
}

/**
 * The Modal opens up using a redux state `selectedClaimId`.
 * When it is closed, claims are refetched.
 */
const NotesModal = () => {
  const dispatch = useAppDispatch()
  const { selectedClaimId } = useAppSelector((state) => state.ui)
  const { permissions } = useGetMyPermissions()

  const onCloseModal = () => {
    dispatch(setSelectedClaimId(""))

    client.refetchQueries({
      include: ["ListClaimsWithPagination"],
    })
  }
  const items: TabItem[] = [
    {
      key: "1",
      label: "Notes",
      children: (
        <>
          {permissions.includes(ADD_NOTES) && <NotesForm />}
          <NotesTable />
        </>
      ),
      permissionCode: VIEW_CLAIM_NOTES,
    },
    {
      key: "2",
      label: "Audit Notes",
      children: <Audit />,
      permissionCode: AUDIT_NOTES,
    },
  ]

  const filteredItems = items.filter((item) =>
    permissions.includes(item.permissionCode)
  )

  return (
    <Modal
      open={!!selectedClaimId}
      footer={null}
      onCancel={onCloseModal}
      className="announcement-modal"
      maskStyle={{
        backgroundColor: "rgb(0, 0, 0, 0.3)",
      }}
      destroyOnClose
      centered
    >
      <Tabs items={filteredItems} />
    </Modal>
  )
}

export default NotesModal
