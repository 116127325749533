import { useMutation, useQuery } from "@apollo/client"
import { Alert, Form, InputNumber, Select, Spin } from "antd"
import { useEffect, useState } from "react"

import Button, { SecondaryButton } from "../../components/styled/Button.styled"
import Container from "../../components/styled/Container.styled"
import { Heading } from "../../components/styled/Heading"
import { errorTimeout } from "../../constants/general"
import RESET_ALERT from "../../constants/resetAlert"
import { UPDATE_VAULT_AMOUNT } from "../../graphql/mutations"
import { GET_ALL_CURRENT_AND_FUTURE_PROMOTIONS } from "../../graphql/queries"
import { IGetAllCurrentAndFuturePromotions } from "../../types/graphqlResponse"

const EditVault = () => {
  const [form] = Form.useForm()
  const promotion = Form.useWatch("promotionId", form)

  const [readOnly, setReadOnly] = useState<boolean>(false)

  const [alert, setAlert] = useState(RESET_ALERT)

  const { data, loading, refetch } =
    useQuery<IGetAllCurrentAndFuturePromotions>(
      GET_ALL_CURRENT_AND_FUTURE_PROMOTIONS,
      {
        onError(error) {
          setAlert({
            type: "error",
            message: error.message,
          })
        },
      }
    )

  useEffect(() => {
    if (alert.message) {
      setTimeout(() => {
        setAlert(RESET_ALERT)
      }, errorTimeout)
    }
  }, [alert])

  // update vault data
  const [updateVaultData, { loading: updatingVaultAmount }] = useMutation(
    UPDATE_VAULT_AMOUNT,
    {
      onCompleted(data) {
        // refetch data from all promotions and set the values.
        refetch().then((data) => {
          const updateAmount = data.data.getAllCurrentAndFuturePromotions.find(
            (item) => item._id === promotion
          )?.remainingAmount
          form.setFieldsValue({
            vaultAmount: updateAmount,
          })
        })

        toggleReadOnly()

        if (data.updateVaultAmount) {
          setAlert({
            type: "success",
            message: "Vault amount updated successfully",
          })
        }
      },
      onError(error) {
        setAlert({
          type: "error",
          message: error.message,
        })
        toggleReadOnly()
      },
    }
  )

  const toggleReadOnly = (): void => setReadOnly((val) => !val)

  function onFinish(values: any) {
    if (!readOnly) {
      toggleReadOnly()
      return
    }

    updateVaultData({
      variables: {
        input: {
          promotionId: values.promotionId,
          value: values.vaultAmount,
        },
      },
    })
  }

  return (
    <>
      <Container>
        <Heading heading="Edit Vault Amount" />

        {/* Error */}
        <div className="row justify-content-center">
          <div className="col col-md-5 my-1">
            {alert.message && (
              <div className="mb-3">
                <Alert {...alert} />
              </div>
            )}
          </div>
        </div>

        <div className="row p-3 justify-content-center">
          <div className="col col-md-5 px-3 py-4 p-md-3 border">
            {readOnly && !updatingVaultAmount && (
              <Alert
                type="info"
                className="my-2"
                message={
                  "Please confirm the vault amount before submitting this form"
                }
              />
            )}

            {data?.getAllCurrentAndFuturePromotions && (
              <Spin spinning={loading || updatingVaultAmount}>
                <Form
                  initialValues={{
                    promotionId: data?.getAllCurrentAndFuturePromotions[0]._id,
                    vaultAmount:
                      data?.getAllCurrentAndFuturePromotions[0].remainingAmount,
                  }}
                  onFinish={onFinish}
                  layout="vertical"
                  requiredMark={false}
                  disabled={readOnly || loading}
                  form={form}
                >
                  <Form.Item
                    name="promotionId"
                    label="Select promotion"
                    rules={[
                      {
                        required: true,
                        message: "Please select a promotion name",
                      },
                    ]}
                  >
                    <Select
                      options={data.getAllCurrentAndFuturePromotions.map(
                        (item) => {
                          return { label: item.name, value: item._id }
                        }
                      )}
                      onChange={(value) => {
                        const UpdatedVaultValue =
                          data.getAllCurrentAndFuturePromotions.find(
                            (item) => value === item._id
                          )?.remainingAmount
                        form.setFieldsValue({
                          vaultAmount: UpdatedVaultValue,
                        })
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name={"vaultAmount"}
                    label={"Vault Amount"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter amount",
                      },
                    ]}
                  >
                    <InputNumber className="w-100" />
                  </Form.Item>

                  <div className="row text-center pt-2">
                    {readOnly && (
                      <>
                        <div className="col">
                          <SecondaryButton
                            disabled={!readOnly}
                            onClick={() => toggleReadOnly()}
                          >
                            Back
                          </SecondaryButton>
                        </div>
                        <div className="col">
                          <Button type="submit" disabled={!readOnly}>
                            Submit
                          </Button>
                        </div>
                      </>
                    )}
                    {!readOnly && (
                      <div className="col">
                        <Button type="submit">Edit</Button>
                      </div>
                    )}
                  </div>
                </Form>
              </Spin>
            )}
          </div>
        </div>
      </Container>
    </>
  )
}

export { EditVault }
