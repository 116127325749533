import { Form, Input } from "antd"
import { validateLeadingTrailingSpaces } from "../../utils/formUtils"

type Props = {
  disabled?: boolean
}

const DealerCity = ({ disabled = false }: Props) => {
  return (
    <Form.Item
      rules={[
        { required: true, message: "Please enter City Name" },
        validateLeadingTrailingSpaces(),
      ]}
      name="dealerCity"
      label="Dealer City"
    >
      <Input disabled={disabled} autoComplete="dealer-city" />
    </Form.Item>
  )
}

export default DealerCity
