import { useQuery } from "@apollo/client"
import { GET_HEADER } from "../graphql/queries"

const useGetProgramHeader = () => {
  const { data } = useQuery<{
    getHeader: {
      content: string
      _id: string
    }
  }>(GET_HEADER)

  return { header: data?.getHeader?.content }
}

export default useGetProgramHeader
