import {
  ApolloClient,
  InMemoryCache,
  gql,
  useMutation,
  useQuery,
} from "@apollo/client"
import { Alert, Form } from "antd"
import HTMLReactParser from "html-react-parser"
import { Fragment, useEffect, useState } from "react"
import LoadingOverlay from "react-loading-overlay-ts"

import KashVaultNoYearLogo from "../../assets/images/KashVaultNoYearLogo.png"
import Footer from "../../components/Layout/Footer"
import Button from "../../components/styled/Button.styled"
import globals from "../../constants/globals"
import headerImage from "../../assets/images/HeaderImage.jpg"
import {
  Address1,
  Address2,
  City,
  Email,
  FirstName,
  LastName,
  Phone,
  State,
  Zip,
} from "../../components/PhysicalCardContent/formItems"
import { useNavigate, useSearchParams } from "react-router-dom"
import { ConsumerPaths } from "../../constants/paths"
import trimFormValues from "../../utils/trimFormValues"
import { getBpsAccountUrl } from "../../utils/general"

const { BRAND_LOGO_HEIGHT, BPS_SYSTEM_URL, PROGRAM_NAME } = globals

const GET_DATA_FROM_TOKEN = gql`
  query PhysicalCardAddress($token: String!) {
    physicalCardAddress(token: $token) {
      fname
      lname
      email
      phone
      address1
      address2
      city
      state
      zip
      status
      trackingNumber
    }
  }
`
const UPDATE_MAILING_ADDRESS = gql`
  mutation UpdateMailingAddress(
    $trackingNumber: String!
    $address: AddressInput
  ) {
    updateMailingAddress(trackingNumber: $trackingNumber, address: $address)
  }
`

const REDEEM_PHYSICAL_CARD = gql`
  mutation PhysicalCardRedeem($input: physicalRedeemInput) {
    physicalCardRedeem(input: $input) {
      error
      message
    }
  }
`
type IGetResponseFromBpsSystem = {
  physicalCardAddress: Partial<IFormValues> & {
    trackingNumber: string
    status: string
  }
}

type IFormValues = {
  fname: string
  lname: string
  email: string
  phone: string
  address1: string
  address2?: string
  city: string
  state: string
  zip: string
}

const clientForBpsSystem = new ApolloClient({
  cache: new InMemoryCache(),
  uri: BPS_SYSTEM_URL,
})

const PhysicalCardContent = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const token = searchParams.get("token") || ""
  const mqSiteUrl = process.env.REACT_APP_MQ_URL
  const [showSpinner, setShowSpinner] = useState<boolean>(true)
  const [showSpinner2, setShowSpinner2] = useState<boolean>(false)
  const [spinnerText, setSpinnerText] = useState({
    text: "Loading your data...",
  })
  const [showErrorMessage, setshowErrorMessage] = useState<boolean>(false)
  const [hideFields, setHideFields] = useState<boolean>(false)
  const [errorMessage, seterrorMessage] = useState<string>("")
  const [trackingNumber, setTrackingNumber] = useState("")
  const [form] = Form.useForm()

  const [updateMailingAddress] = useMutation(UPDATE_MAILING_ADDRESS)

  // Redeem Physical Card
  const [redeemPhysicalCard] = useMutation(REDEEM_PHYSICAL_CARD, {
    client: clientForBpsSystem,
    onCompleted(data) {
      setShowSpinner2(false)
      if (data.physicalCardRedeem.error) {
        setshowErrorMessage(true)
        seterrorMessage(data.physicalCardRedeem.message)
      } else {
        navigate(ConsumerPaths.PHYSICAL_CARD_REDEEMED)
      }
    },
  })

  // Calling for data on load using token
  useQuery<IGetResponseFromBpsSystem>(GET_DATA_FROM_TOKEN, {
    client: clientForBpsSystem,
    variables: {
      token,
    },
    skip: !token,
    onCompleted({ physicalCardAddress }) {
      setShowSpinner(false)
      if (physicalCardAddress.status !== "success") {
        setshowErrorMessage(true)
        seterrorMessage(physicalCardAddress.status || "Something went wrong!")
        if (
          physicalCardAddress.status ===
          "This token has already been redeemed and cannot be redeemed again"
        ) {
          setHideFields(true)
          seterrorMessage(
            "The mailing address has already been confirmed for this payment."
          )
        }
      } else {
        setTrackingNumber(physicalCardAddress.trackingNumber)
        form.setFieldsValue(physicalCardAddress)
        setshowErrorMessage(false)
      }
    },
  })

  const handleSubmit = (rawValues: IFormValues) => {
    const values: IFormValues = trimFormValues(rawValues)

    setShowSpinner2(true)
    setSpinnerText({ text: "Your card request is being processed..." })

    updateMailingAddress({
      variables: {
        trackingNumber,
        address: {
          zip: values.zip,
          street2: values.address2,
          street1: values.address1,
          state: values.state,
          city: values.city,
        },
      },
    })

    redeemPhysicalCard({
      variables: {
        input: {
          token,
          ...values,
        },
      },
    })
  }

  useEffect(() => {
    if (mqSiteUrl) {
      // Redirect to the specified URL if REACT_APP_MQ_URL is present
      setSpinnerText({ text: "Redirecting to Cardholder site..." })
      window.location.replace(getBpsAccountUrl(token, "visareward"))
    }
  }, [token, mqSiteUrl])

  return (
    <Fragment>
      <LoadingOverlay
        active={showSpinner || showSpinner2}
        spinner
        text={spinnerText.text}
      >
        <div className="container-fluid pt-2">
          <img
            src={KashVaultNoYearLogo}
            className="d-inline-block align-top"
            height={BRAND_LOGO_HEIGHT}
            alt="Logo"
          />
        </div>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-8 position-relative">
              <img
                src={headerImage}
                alt="Header kia"
                style={{ height: "380px", width: "100%", objectFit: "cover" }}
              />
              <div
                className="position-absolute text-white"
                style={{ bottom: "1rem", left: "2rem" }}
              >
                <div className="text-uppercase fw-bold fs-2">
                  Welcome to the
                </div>
                <div className="text-uppercase fw-bolder fs-1">
                  {PROGRAM_NAME}
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-3 pb-5 justify-content-center">
            <div className="col-md-8">
              <Form
                form={form}
                className="row gx-3 justify-content-center"
                layout="vertical"
                requiredMark={false}
                onFinish={handleSubmit}
              >
                <h4 className="fs-20 fw-bold bps-midnight">Your Information</h4>
                <Alert
                  type="error"
                  style={{ display: showErrorMessage ? "block" : "none" }}
                  message={HTMLReactParser(errorMessage)}
                  className="mb-3"
                />
                <div className="col-sm-6">
                  <FirstName />
                </div>
                <div className="col-sm-6">
                  <LastName />
                </div>
                <div className="col-sm-6">
                  <Email />
                </div>
                <div className="col-sm-6">
                  <Phone />
                </div>

                <h4 className="fs-20 fw-bold bps-midnight pt-4">
                  Mailing Address
                </h4>

                <div className="col-sm-6">
                  <Address1 />
                </div>
                <div className="col-sm-6">
                  <Address2 />
                </div>
                <div className="col-sm-6">
                  <City />
                </div>
                <div className="col-sm-3">
                  <State />
                </div>
                <div className="col-sm-3">
                  <Zip />
                </div>
                <div
                  className="row"
                  style={{ display: hideFields ? "none" : "block" }}
                >
                  <div className="col-sm-2 offset-sm-5">
                    <Button type="submit">Submit</Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <Footer />
      </LoadingOverlay>
    </Fragment>
  )
}

export default PhysicalCardContent
