import { Alert, Form } from "antd"
import { useMutation } from "@apollo/client"
import { SUBMIT_CONTACT_FORM } from "../graphql/mutations"
import Button from "../components/styled/Button.styled"
import { useState } from "react"
import globals from "../constants/globals"
import { Heading } from "../components/styled/Heading"
import Container from "../components/styled/Container.styled"
import Subject from "../components/ContactUs/Subject"
import Name from "../components/ContactUs/Name"
import Email from "../components/ContactUs/Email"
import Message from "../components/ContactUs/Message"
import ContactUsInformation from "../components/ContactUs/ContactUsInformation"
import ContactUsSuccess from "../components/ContactUs/ContactUsSuccess"
import {
  ContactUsContainer,
  FormContainer,
} from "../components/ContactUs/styled/Components.styled"
import Spinner from "../components/Spinner"
import Header from "../components/Layout/Header"
import Footer from "../components/Layout/Footer"
import { useAuthStatus } from "../hooks/useAuthStatus"

type IContactUs = {
  email: string
  message: string
  name: string
  subject: string
}

const { IS_ADMIN } = globals

const ContactUs = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [submitContactForm, { loading, error, reset }] =
    useMutation(SUBMIT_CONTACT_FORM)

  const onFinish = (values: IContactUs): void => {
    reset()
    let inputValue = {
      ...values,
      signingInFromConsumerOrAdmin: IS_ADMIN ? "admin" : "consumer",
    }
    submitContactForm({
      variables: { input: inputValue },
      onCompleted: () => {
        setShowSuccessMessage(true)
      },
    })
  }

  return (
    <>
      <Container>
        <Heading heading="Contact Us" />

        <ContactUsContainer>
          <FormContainer>
            {showSuccessMessage ? (
              <ContactUsSuccess />
            ) : (
              <div className="row">
                <h5 className="mb-3">
                  <strong>Send a message</strong>
                </h5>

                <Form
                  layout="vertical"
                  onFinish={onFinish}
                  requiredMark={false}
                >
                  <Subject />
                  <Name />
                  <Email />
                  <Message />

                  {loading && (
                    <div className="col mb-3">
                      <Spinner />
                    </div>
                  )}

                  {error && (
                    <div className="col mb-3">
                      <Alert type="error" message={error.message} />
                    </div>
                  )}

                  <div className="col-md-6 offset-md-3">
                    <Button type="submit" disabled={loading}>
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </FormContainer>

          <ContactUsInformation />
        </ContactUsContainer>
      </Container>
    </>
  )
}

const ContactUsConsumerUnprotected = () => {
  const { loggedIn } = useAuthStatus()

  return (
    <>
      <Header forEnrollmentForm={!loggedIn} />
      <ContactUs />
      <Footer />
    </>
  )
}

export default ContactUs

export { ContactUsConsumerUnprotected }
