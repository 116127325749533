import { ApolloClient, InMemoryCache, gql, useMutation } from "@apollo/client"
import { Alert } from "antd"
import HTMLReactParser from "html-react-parser"
import { useEffect, useState } from "react"
import LoadingOverlay from "react-loading-overlay-ts"
import { useSearchParams } from "react-router-dom"

import HeroSection from "../../components/styled/HeroSection.styled"
import KashVaultNoYearLogo from "../../assets/images/KashVaultNoYearLogo.png"
import Footer from "../../components/Layout/Footer"
import globals from "../../constants/globals"
import { getBpsAccountUrl } from "../../utils/general"

const REDEEM_VIRTUAL_CARD = gql`
  mutation VirtualCardRedeem($token: String!) {
    virtualCardRedeem(token: $token) {
      customErrorMessage
      redeemToken
      redeemUrl
    }
  }
`

type VirtualCardRedeemResponse = {
  virtualCardRedeem: {
    customErrorMessage: string
    redeemToken: string
    redeemUrl: string
  }
}

const { BRAND_LOGO_HEIGHT, PROGRAM_NAME, BPS_SYSTEM_URL } = globals

const clientForBpsSystem = new ApolloClient({
  cache: new InMemoryCache(),
  uri: BPS_SYSTEM_URL,
})

const VirtualCardContent = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get("token") || ""
  const mqSiteUrl = process.env.REACT_APP_MQ_URL
  const [errorMessage, setErrorMessage] = useState<string>("")

  const [redeemVirtualCard, { loading }] =
    useMutation<VirtualCardRedeemResponse>(REDEEM_VIRTUAL_CARD, {
      client: clientForBpsSystem,
      onCompleted(data) {
        if (data.virtualCardRedeem.redeemToken === "queued") {
          setErrorMessage("Request taking longer than usual.")
        } else if (data.virtualCardRedeem.redeemToken === "error") {
          setErrorMessage(data.virtualCardRedeem.customErrorMessage)
        } else {
          window.location.href = data.virtualCardRedeem.redeemUrl
        }
      },
      onError(error) {
        setErrorMessage(error.message)
      },
    })

  useEffect(() => {
    if (mqSiteUrl) {
      // Redirect to the specified URL if REACT_APP_MQ_URL is present
      window.location.replace(getBpsAccountUrl(token, "virtualvisa"))
    }
  }, [mqSiteUrl, token])

  useEffect(() => {
    if (!token && !mqSiteUrl) {
      redeemVirtualCard({
        variables: {
          token,
        },
      })
    }
  }, [token, redeemVirtualCard])

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        text="Redirecting to CardAccount site..."
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-10">
              <img
                src={KashVaultNoYearLogo}
                className="d-inline-block align-top"
                height={BRAND_LOGO_HEIGHT}
                alt="Logo"
              />
            </div>
          </div>
        </div>
        <div className="container-fluid pt-2 vh-150">
          <div className="row justify-content-center text-center">
            <div className="col-md-8">
              <HeroSection heading={PROGRAM_NAME} />
            </div>
          </div>
          <div className="row py-5 justify-content-center">
            <div className="col-md-8">
              <Alert
                type="error"
                style={{ display: errorMessage ? "block" : "none" }}
                message={HTMLReactParser(errorMessage)}
                className="mb-3"
              />
            </div>
          </div>
        </div>
        <Footer />
      </LoadingOverlay>
    </>
  )
}

export { VirtualCardContent }
