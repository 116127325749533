
import { Alert, Divider } from "antd";
import { useState } from "react";
import MessageInbox from "./MessageInbox";
import EmailInbox from "./EmailInbox";
import { ConsumerPermissions } from "../../constants/permissions";
import useGetMyPermissions from "../../hooks/useGetMyPermissions";


const Inbox = () => {

 const { VIEW_TEXT_MESSAGES } = ConsumerPermissions
 const { permissions } = useGetMyPermissions()
 const [alertmessage, setAlertMessage] = useState<JSX.Element>();
 const eMessage = (message: string) => {
  setAlertMessage(() => { return <Alert type="error" message={message}></Alert> })
 }

 return (
  <>
   <div className="row justify-content-start container-fluid">
    <div className="col-md-12">
     <h3>Inbox</h3>
    </div>
    <Divider />
    <div className="col-md-6 mb-3">
     {alertmessage}
    </div>
    <div className="col-md-12">
     <EmailInbox errorMessage={(erMessage: string) => eMessage(erMessage)} />
     {
      permissions.includes(VIEW_TEXT_MESSAGES) && <MessageInbox errorMessage={(erMessage: string) => eMessage(erMessage)} />
     }
    </div>
   </div>

  </>
 )

}

export default Inbox;