import HeroSection from "../components/styled/HeroSection.styled"
import globals from "../constants/globals"
import ProgramHeader from "../components/Home/ProgramHeader"
import Announcements from "../components/Home/Announcements"
import { Heading } from "../components/styled/Heading"
import Container from "../components/styled/Container.styled"
import AnnouncementButton from "../components/Home/AnnouncementButton"
import useGetMyPermissions from "../hooks/useGetMyPermissions"
import { AdminPermissions } from "../constants/permissions"
import Leaderboard from "../components/Layout/LeaderBoard"
// import  { ArrowDownOutlined } from '@ant-design/icons'

const { PROGRAM_NAME, IS_ADMIN } = globals
const { ADD_HOME_ANNOUNCEMENT } = AdminPermissions

const Home = () => {
  const { permissions } = useGetMyPermissions()

  const hasPermissionToAddAnnouncement =
    permissions.includes(ADD_HOME_ANNOUNCEMENT) && IS_ADMIN

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-9 px-0">
            <HeroSection
              heading={PROGRAM_NAME}
              description={<ProgramHeader />}
               
            />
          </div>
          <div className="col-xl-3 px-0">
            <Leaderboard />
          </div>
        </div>
      </div>

      <div className="row mx-3 pb-4">
        <div className="col">
        <Heading
          heading="Announcements"
          render={hasPermissionToAddAnnouncement && <AnnouncementButton />}
        />

        <Announcements />
        </div>
      </div>
    </>
  )
}

//  TODO
/* Participant Site Preview Text -- Add this section when the dashboard becomes available */
/* <div className="container pt-5 px-4 mb-4">
        <Heading
          heading="Program Announcements"
          description="Preview the participant site home page, add program announcements, and customize the home page copy."
        />
      </div>
      <hr className="participant-preview-border" />
      <div className="participant-preview-text ">
        <ArrowDownOutlined className="pe-4" />
        Participant Site Preivew
        <ArrowDownOutlined className="ps-4" />
      </div> */

export default Home
