const truncate = (string: string, length: number, divWidth: number) => {
 let width = {
  form: 200,
  to: 300
 }
 if (!!string) {
  if (string?.length > length && divWidth > width.form && divWidth < width.to) {
   return `${string.substring(0, length).trim()}...`
  } else {
   return string.trim()
  }
 }
}

export { truncate }