import { gql } from "@apollo/client"

const GET_AUDIT_NOTES = gql`
  query AuditLogs($claimId: ID!) {
    notesByClaimId(claimId: $claimId) {
      auditLogs {
        _id
        fieldName
        oldValue
        newValue
        createdAt
        userName
      }
    }
  }
`

export { GET_AUDIT_NOTES }