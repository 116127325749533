import { Form, Input } from "antd"

type Props = {
  disabled?: boolean
}

const DealerZIP = ({ disabled = false }: Props) => {
  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: "Please enter a ZIP code",
        },
        {
          pattern: /^[0-9]{5}$/,
          message: "ZIP code must be 5 digits",
        },
      ]}
      name="dealerZip"
      label="Dealer ZIP"
    >
      <Input disabled={disabled} autoComplete="dealer-zip" />
    </Form.Item>
  )
}

export default DealerZIP
