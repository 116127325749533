import { NavLink } from "./CustomLink"

type NavItemProps = {
  label: string
  to: string
  end?: boolean
}

const NavItem = ({ label, to, end = false }: NavItemProps) => {
  return (
    <li className="nav-item">
      <NavLink className="nav-link" to={to} end={end}>
        <span>{label}</span>
      </NavLink>
    </li>
  )
}

export default NavItem
