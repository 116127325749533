import { useMutation } from "@apollo/client"
import { Form, Input, Spin } from "antd"
import { useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"

import heroImage from "../../assets/images/HeaderImage.jpg"
import Footer from "../../components/Layout/Footer"
import Header from "../../components/Layout/Header"
import Button from "../../components/styled/Button.styled"
import globals from "../../constants/globals"
import { CommonPaths } from "../../constants/paths"
import { SIGNIN } from "../../graphql/mutations"
import { useAuthStatus } from "../../hooks/useAuthStatus"
import useGetMyPermissions from "../../hooks/useGetMyPermissions"
import { login, setTokenTimeout } from "../../redux/auth/authSlice"
import { useAppDispatch } from "../../redux/hooks"
import { ISigninResponse } from "../../types/graphqlResponse"
import { setAuthToken } from "../../utils/auth"
import { Heading, Muted, SignInHeroContainer } from "./styled"
import { useSsoSignIn } from "../../hooks/useSsoSignIn"

const { IS_ADMIN, PROGRAM_NAME } = globals

const AdminSignIn = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { loggedIn } = useAuthStatus()

  const state = location.state as { from: Location }
  const from = state ? state.from.pathname : CommonPaths.HOME_PATH

  const [signinUser, { loading, error }] = useMutation<ISigninResponse>(SIGNIN)
  const { loading: loadingPermissions } = useGetMyPermissions()

  const signIn = (values: { userName: string; password: string }) => {
    signinUser({
      variables: {
        ...values,
        signingInFromConsumerOrAdmin: "admin",
      },
      onCompleted: (data) => {
        setAuthToken({
          token: data.signIn.token,
          refresh_token: data.signIn.refreshToken,
        })
        dispatch(setTokenTimeout(data.signIn.tokenTime))
        dispatch(login())
      },
    })
  }

  useSsoSignIn()

  useEffect(() => {
    if (loggedIn && !loadingPermissions) {
      navigate(from, { replace: true })
    }
  }, [from, navigate, loggedIn, loadingPermissions])

  return (
    <>
      <SignInPageHeader />

      <div className="container-fluid py-5">
        <Heading>Sign In</Heading>
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-6 col-md-8 col-10 border p-5">
            <Form onFinish={signIn} layout="vertical" requiredMark={false}>
              <Form.Item
                name="userName"
                label="Username"
                rules={[
                  {
                    required: true,
                    message: "Please enter your username",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              {loading ? (
                <div className="row justify-content-center pt-1">
                  <div className="col text-center">
                    <Spin />
                  </div>
                </div>
              ) : (
                error && (
                  <div className="row pt-1">
                    <div className="col">
                      <div className="alert alert-danger">{error?.message}</div>
                    </div>
                  </div>
                )
              )}
              <div className="row pt-3 text-center">
                <div className="col">
                  <Button type="submit">Sign In</Button>
                </div>
              </div>
            </Form>

            <div className="row pt-4 text-end">
              <Link
                to={CommonPaths.FORGOT_PASSWORD_PATH}
                className="custom-link"
              >
                Forgot Password
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

const SignInPageHeader = () => (
  <>
    <Header forEnrollmentForm={!IS_ADMIN} />
    <SignInHeroContainer>
      <img src={heroImage} alt="Cover" />

      <div>
        <Muted>{PROGRAM_NAME}</Muted>
      </div>
    </SignInHeroContainer>
  </>
)

export { AdminSignIn }
