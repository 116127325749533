import { gql, useLazyQuery } from "@apollo/client"
import { Alert, Form, Input } from "antd"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Footer from "../../components/Layout/Footer"
import Header from "../../components/Layout/Header"
import Spinner from "../../components/Spinner"
import Button from "../../components/styled/Button.styled"
import Container from "../../components/styled/Container.styled"
import RESET_ALERT from "../../constants/resetAlert"
import { ISigninResponse } from "../../types/graphqlResponse"

/**
 * This page allows a consumer to login and go to enrollment form
 * This is a hack configurable from .env. It is in place to recreate SSO login
 * mock version
 */
const MockSSO = () => {
  const navigate = useNavigate()
  const [alert, setAlert] = useState(RESET_ALERT)
  const [getEncryptedEmail, { loading }] = useLazyQuery<{
    getEncryptedEmail: string
  }>(GET_ENCRYPTED_EMAIL)

  const [signin, { loading: loading2 }] = useLazyQuery<{
    getSignInResponseFromEmail: ISigninResponse["signIn"]
  }>(SIGNIN_USER_BYPASS)

  const [form] = Form.useForm()
  const email = Form.useWatch("email", form)

  const signInUser = () => {
    setAlert(RESET_ALERT)
    form.validateFields().then(() => {
      signin({
        variables: { email },
        onCompleted(data) {
          if (data.getSignInResponseFromEmail) {
            return navigate(
              `/signin?token=${data.getSignInResponseFromEmail.token}&refreshToken=${data.getSignInResponseFromEmail.refreshToken}&tokenTime=${data.getSignInResponseFromEmail.tokenTime}`
            )
          }
          setAlert({
            type: "error",
            message: "Sign in failed",
          })
        },
        onError(error) {
          setAlert({
            type: "error",
            message: error.message,
          })
        },
      })
    })
  }

  const enrollUser = () => {
    setAlert(RESET_ALERT)
    form.validateFields().then(() => {
      getEncryptedEmail({
        variables: { email },
        onCompleted(data) {
          navigate(`/enrollment-form?token=${data.getEncryptedEmail}`)
        },
        onError(error) {
          setAlert({ type: "error", message: error.message })
        },
      })
    })
  }

  return (
    <>
      <Header showNone />

      <Container>
        <div className="row justify-content-center py-5">
          <div className="col-xl-6 col-lg-6 col-md-8 col-10 border p-5">
            <Form form={form}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                  {
                    required: true,
                    message: "Please enter your email address",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>

            {alert.message && <Alert {...alert} className="my-3" />}
            {(loading || loading2) && <Spinner />}

            <div className="row">
              <div className="col-6">
                <Button type="button" onClick={enrollUser}>
                  Enrollment Form
                </Button>
              </div>
              <div className="col-6">
                <Button type="button" onClick={signInUser}>
                  Sign In
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default MockSSO

const GET_ENCRYPTED_EMAIL = gql`
  query GetEncryptedEmail($email: String) {
    getEncryptedEmail(email: $email)
  }
`

const SIGNIN_USER_BYPASS = gql`
  query GetSignInResponseFromEmail($email: String) {
    getSignInResponseFromEmail(email: $email) {
      token
      refreshToken
      tokenTime
    }
  }
`
