enum AdminPaths {
  ENROLLMENT_LIST_PATH = "/enrollment-list",
  DEALER_UPLOADER_PATH = "/dealer-upload",
  PRODUCT_UPLOADER_PATH = "/product-upload",
  PARTICIPANT_UPLOADER_PATH = "/participant-upload",
  QUIZZES_PATH = "/quizzes",
  PROGRAM_COMMUNICATIONS_PATH = "/program-communications",
  CLAIMS_PATH = "/claims",
  CLAIM_REPORT_PATH = "/claim-report",
  ENROLLMENT_REPORT_PATH = "/enrollment-report",
  CHANGE_ADDRESS_REPORT_PATH = "/change-info-report",
  ADD_INCENTIVE_PATH = "/add-incentive",
  UPDATE_INCENTIVE_PATH = "/update-incentive/:productId",
  ADD_USER_PATH = "/add-user",
  USERS_LIST_PATH = "/users",
  CLAIM_APPROVAL_PATH = "/claim-approval",
  EDIT_CLAIM_PATH = "/edit-claim",
  INVOICE_ORDER_PATH = "/invoice-order",
  ANNOUNCEMENT_PATH = "/announcements",
  EDIT_VAULT_PATH = "/edit-vault-amount",
  PROMOTIONS_UPLOADER_PATH = '/promotions-uploader',
}

enum ConsumerPaths {
  ENROLLMENT_FORM_PATH = "/enrollment-form",
  CLAIM_SALE_PATH = "/claim-sale",
  MY_ACCOUNT_PATH = "/my-account",
  MY_PROFILE_PATH = "/my-account/profile",
  MY_INBOX_PATH = "/my-account/inbox",
  MY_FUNDING_HISTORY_PATH = "/my-account/funding-history",
  PHYSICAL_CARD_CONTENT = "/redeemphysicalcard",
  PHYSICAL_CARD_REDEEMED = "/physicalcardredeemed",
  VIRTUAL_CARD_CONTENT = "/redeemvirtualcard",
}

enum CommonPaths {
  HOME_PATH = "/",
  CONTACTUS_PATH = "/contactus",
  SIGNIN_PATH = "/signin",
  SIGNOUT_PATH = "/signout",
  CURRENT_INCENTIVES_PATH = "/current-incentives",
  RESOURCES_FAQ_PATH = "/resources-faq",
  RESOURCES_RULES_PATH = "/resources-program-rules",
  UPDATE_PASSWORD_PATH = "/update-password/:token",
  FORGOT_PASSWORD_PATH = "/forgot-password",
}

export { AdminPaths, ConsumerPaths, CommonPaths }
