// @ts-ignore
import { createUploadLink } from "apollo-upload-client"
import { ApolloLink, split } from "@apollo/client"
import { getMainDefinition } from "@apollo/client/utilities"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { createClient } from "graphql-ws"
import { ApolloClient, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import globals from "../constants/globals"
import {
  authTokenKey,
  getAuthToken,
  getRefreshToken,
  INVALID_SESSION_TOKEN,
} from "./auth"
import { GET_TOKEN_FROM_REFRESH_TOKEN } from "../graphql/queries"
import { IGetRefreshTokenResponse } from "../types/graphqlResponse"
import { store } from "../redux/store"

const { GRAPHQL_ENDPOINT, GRAPHQL_SUBSCRIPTION_ENDPOINT } = globals

const httpLink = createUploadLink({
  uri: GRAPHQL_ENDPOINT,
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: GRAPHQL_SUBSCRIPTION_ENDPOINT + "",
  })
)

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken()
  const loggedIn = store.getState().auth.loggedIn

  /**
   * Reload the page if for some reason the token does not exist
   * and the user is logged in according to the redux state.
   * This will log the user out before making the request without token
   */
  if (loggedIn && !token) {
    window.location.reload()
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  }
})

/**
 * This client instance is to avoid sending the token in the request
 */
const authClient = new ApolloClient({
  cache: new InMemoryCache(),
  uri: GRAPHQL_ENDPOINT,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
  },
})

/**
 * This is used to find out if any error contains the code
 * for INVALID_SESSION_TOKEN. If yes, then new token is asked for using refresh token.
 * If error, clears the localStorage and refreshes the page
 */
const errorlink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions.code === INVALID_SESSION_TOKEN) {
        let refreshToken = getRefreshToken
        authClient
          .query<IGetRefreshTokenResponse>({
            query: GET_TOKEN_FROM_REFRESH_TOKEN,
            variables: { refreshToken: refreshToken() },
          })
          .then((res) => {
            if (!!res.data.refreshSessionToken.token) {
              localStorage.setItem(
                authTokenKey,
                res.data.refreshSessionToken.token
              )
              window.location.reload()
            }
          })
          .catch((errors) => {
            if (!!errors) {
              localStorage.clear()
              window.location.reload()
            }
          })
      }
    })
  }
})

const httpLinkErrHandling = ApolloLink.from([errorlink, httpLink])

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    )
  },
  wsLink,
  authLink.concat(httpLinkErrHandling)
)

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
  },
})

export default client

export { authClient }
