import { Form, Input } from "antd"
import { validateLeadingTrailingSpaces } from "../../utils/formUtils"

const Address2 = () => {
  return (
    <Form.Item
      name="street2"
      label="Address 2"
      rules={[validateLeadingTrailingSpaces()]}
    >
      <Input autoComplete="new-password" />
    </Form.Item>
  )
}

export default Address2
