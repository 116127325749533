import { Form, Input } from "antd"

type Props = { disabled?: boolean }

const KUID = ({ disabled }: Props) => {
  return (
    <Form.Item name="kuid" label="KUID">
      <Input autoComplete="new-password" disabled={disabled} />
    </Form.Item>
  )
}

export default KUID
