import { Form, Input } from "antd"

type Props = { disabled?: boolean }

const DealerNameReadOnly = ({ disabled }: Props) => {
  return (
    <Form.Item
      name="dealerId"
      rules={[
        {
          required: true,
          message: "Please enter the dealer name",
        },
      ]}
    >
      <Input disabled={disabled} />
    </Form.Item>
  )
}

export default DealerNameReadOnly
