import { Form, Input } from "antd"
import { validateLeadingTrailingSpaces } from "../../utils/formUtils"

const Message = () => {
  return (
    <Form.Item
      label="Message"
      name="message"
      rules={[
        {
          required: true,
          message: "Please enter your message",
        },
        validateLeadingTrailingSpaces()
      ]}
    >
      <Input.TextArea rows={5} />
    </Form.Item>
  )
}

export default Message
