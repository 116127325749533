import styled from "styled-components"

const Button = styled.button.attrs(() => ({
  className: "btn",
}))`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primaryColor} !important;
  color: #fff !important;
  border-color: ${({ theme }) => theme.colors.primaryColor};
  border-radius: 0;
  font-weight: 600;
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryColor} !important;
  }

  &:focus {
    background-color: #ccc;
    border-color: #ccc;
  }

  &:disabled {
    background-color: #ccc;
    color: #888;
    border: none;
  }
`

const SecondaryButton = styled.button.attrs(() => ({
  className: "secondary-btn",
}))`
  width: 100%;
  background-color: #fff !important;
  color: ${({ theme }) => theme.colors.secondaryColor} !important;
  border: 1px solid ${({ theme }) => theme.colors.secondaryColor} !important;
  padding: 0.4rem 1.2rem;
  border-radius: 0;
  font-weight: 500;
  &:hover {
    background-color: #697279 !important;
    color: #fff !important;
    border: 1px solid #697279 !important;
  }

  &:focus,
  &:disabled {
    background-color: #ccc;
    border: #ccc;
    color: #888;
  }
  &:focus {
    outline: none;
  }
`
const LinkButton = ({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) => {
  return (
    <button
      className={`bg-transparent border-0 text-decoration-underline custom-link ${className}`}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button

export { SecondaryButton, LinkButton }
