import { useQuery } from "@apollo/client"
import { Table } from "antd"
import { ColumnsType } from "antd/es/table"

import globals from "../../constants/globals"
import { GET_FUNDING_HISTORY } from "../../graphql/queries"
import {
  IGetFundedHistory,
  IGetFundedHistoryRecord,
} from "../../types/graphqlResponse"
import { formatAmount, getFormattedDate } from "../../utils/date"

const FundindHistoryColumns: ColumnsType<IGetFundedHistoryRecord> = [
  {
    title: "Funding Date",
    dataIndex: "fundedDate",
    render: (fundedDate) => getFormattedDate(fundedDate),
  },
  {
    title: "Tracking Number",
    dataIndex: "trackingNumber",
  },
  {
    title: "Funding Amount",
    dataIndex: "totalSumOfClaims",
    render: (totalSumOfClaims) => formatAmount(totalSumOfClaims),
  },
]

export default function FundingHistory({ userId }: { userId?: string }) {
  const { IS_ADMIN } = globals

  const { data, loading } = useQuery<IGetFundedHistory>(GET_FUNDING_HISTORY, {
    variables: IS_ADMIN
      ? { signingInFromConsumerOrAdmin: "admin", userId }
      : { signingInFromConsumerOrAdmin: "consumer" },
  })

  return (
    <Table
      columns={FundindHistoryColumns}
      dataSource={data?.orderHistoryByUserId}
      scroll={{ x: "max-content" }}
      loading={loading}
    />
  )
}
