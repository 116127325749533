import { Modal } from "antd"
import { IAnnouncement } from "../../types/graphqlResponse"
import AnnouncementForm from "./AnnouncementForm"

/** Faced a bug while editing form in this module
 * The React Quill editor was showing the content without any formatting
 * The solution was to use the forceRender prop in the Modal component
 * To keep the modal open in the background
 * Since the Modal and form components are already mounted, the forms inside don't get the values,
 * when we click on the edit button
 * So, we need to use the `open` prop to check if the modal is open or not,
 * and then render the form inside the modal.
 * Both these steps somehow make the React Quill editor work properly
 */

type AnnouncementModalProps = {
  open: boolean
  toggleModal: () => void
  itemToEdit?: IAnnouncement
}

const AnnouncementModal = ({
  open,
  toggleModal,
  itemToEdit,
}: AnnouncementModalProps) => {
  return (
    <Modal
      open={open}
      footer={null}
      onCancel={toggleModal}
      className="announcement-modal"
      maskStyle={{
        backgroundColor: "rgb(0, 0, 0, 0.3)",
      }}
      destroyOnClose
      forceRender
      centered
    >
      <div className="row justify-content-center py-2">
        <div className="col-md-10">
          <h4 className="mb-4">
            {itemToEdit ? "Edit" : "Add"} Program Announcement
          </h4>
          {open && (
            <AnnouncementForm
              toggleModal={toggleModal}
              itemToEdit={itemToEdit}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default AnnouncementModal
