import {
  Link as RouterLink,
  LinkProps,
  NavLink as RouterNavLink,
  NavLinkProps,
  useLocation,
} from "react-router-dom"

/**
 * This file exports two components that are basically
 * the same as the react-router-dom Link and NavLink components.
 * Additional feature is that the page is made to refresh when
 * the if the page requested is the same as the current page
 */

function Link({ children, ...props }: LinkProps) {
  const { pathname } = useLocation()

  const isSamePath = pathname === props.to || pathname.slice(0, -1) === props.to

  const onClick = () => {
    if (isSamePath) {
      window.location.reload()
    }
  }

  return (
    <RouterLink onClick={onClick} {...props}>
      {children}
    </RouterLink>
  )
}

function NavLink({ children, ...props }: NavLinkProps) {
  const { pathname } = useLocation()

  const isSamePath = pathname === props.to || pathname.slice(0, -1) === props.to

  const onClick = () => {
    if (isSamePath) {
      window.location.reload()
    }
  }

  return (
    <RouterNavLink onClick={onClick} {...props}>
      {children}
    </RouterNavLink>
  )
}

export { Link, NavLink }
