import { gql } from "@apollo/client"

// To request enrollment link for other programs
const REQUEST_ENROLLMENT_LINK = gql`
  mutation RequestSingleSignOn(
    $programCode: String!
    $token: String!
    $isNewEnrolment: Boolean
  ) {
    requestSingleSignOn(
      programCode: $programCode
      token: $token
      isNewEnrolment: $isNewEnrolment
    )
  }
`

const KIAKASH_CHECK_ELIGIBILITY = gql`
  query Query($token: String!) {
    checkJobTitleEligibility(token: $token)
  }
`

const GET_CURRENT_PROMOTIONS = gql`
  query GetCurrentPromotions {
    requestCurrentPromotions {
      _id
      name
      startDate
      endDate
      createdAt
      amount
      fromProgram
    }
  }
`

export {
  REQUEST_ENROLLMENT_LINK,
  KIAKASH_CHECK_ELIGIBILITY,
  GET_CURRENT_PROMOTIONS,
}
