import { useQuery } from "@apollo/client"
import { Spin } from "antd"
import "chart.js/auto"
import { useEffect, useRef, useState } from "react"
import { Doughnut } from "react-chartjs-2"
import styled from "styled-components"
import vaultImg from "../../assets/images/vault.svg"
import globals from "../../constants/globals"
import { GET_LEADERBOARD_DATA } from "../../graphql/queries"
import { formatAmount } from "../../utils/date"
import { truncate } from "../../utils/TruncateString"

const LeaderboardList = styled.div`
  list-style-type: none;
  width: 100%;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: #fff;
  }
  li {
    font-weight: bold;
    border-bottom: 1px solid #eee;
    padding: 0.5em 0em;
    font-size: 1rem;
  }

  li:last-child {
    border-bottom: none;
    padding-bottom: 1em;
  }

  .leaderboard-awards {
    float: right;
    text-align: right;
  }

  .leaderboard-city {
    font-weight: normal;
    font-size: .8rem;
    padding-left: 0.25em;
    display: inline;

    @media screen and (max-width: 1200px) {
      display: block;
    }
  }
`
const Vault = styled.div`
  background-color: #697279;
  border-radius: .5rem;
  text-align: center;
  position: relative;

  .remaining-funds{
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    font-size: 1.5rem;

    @media screen and (max-width: 1600px) {
      font-size: 1rem;
    }
  }
  
  // /*------styling for vault*/
  .vault-circle {
    width: 64% !important;
    height: 64% !important;
    margin: auto;

    @media screen and (max-width: 1600px) {
      width: 40% !important;
      height: 40% !important;
    }

    @media screen and (max-width: 1200px) {
      width: 100% !important;
      height: 100% !important;
    }
  }

  }
`

const MyCurrentStanding = styled.div`
  color: #fff;
  background-color: #37434d;
  width: 100%;
  padding: 0.5em 1em !important;

  h6 {
   
    // font-size: 10px;
  }
  
`

const Leaderboard = () => {
  const { data, loading, error } = useQuery<any>(GET_LEADERBOARD_DATA, {
    variables: {
      signingInFromConsumerOrAdmin: globals.IS_ADMIN ? "admin" : "consumer",
    },
  })

  const listRef = useRef<any>()
  const [divWidth, setDivWidth] = useState<number>()

  const [chartData, setChartData] = useState<any>()

  const getGradient = (chart: any) => {
    const {
      ctx,
      chartArea: { top, bottom, left, right },
    } = chart
    const gradientSegment = ctx.createLinearGradient(left, top, right, bottom)
    gradientSegment.addColorStop(0, "#05141F")
    gradientSegment.addColorStop(1, "#5D7D2B")
    return gradientSegment
  }

  useEffect(() => {
    setDivWidth(listRef?.current?.offsetWidth)
    setChartData({
      datasets: [
        {
          data: [
            data?.getLeaderboardDetails?.vaultData.filledAmount,
            data?.getLeaderboardDetails?.vaultData.totalAmount -
            data?.getLeaderboardDetails?.vaultData.filledAmount,
          ],
          backgroundColor: (context: any) => {
            const chart = context.chart
            const { ctx, chartArea } = chart
            if (!chartArea) {
              return null
            }
            if (context.dataIndex === 0) {
              return "#fff"
            } else {
              return getGradient(chart)
            }
          },
          cutout: "90%",
          borderWidth: 0,
          hoverOffset: 10,
          radius: "90%",
        },
      ],
    })
  }, [listRef, loading, data])

  return (
    <>
      {!loading && !error ? (
        <div className="container-fluid custom-bg py-2 h-100">
          <div className="row">
            <div className="col-xl-12 col-md-6 col-xs-12 px-4 py-2">
              <Vault>
                <h5 className="text-uppercase text-center text-white pt-2 mb-0">
                  Kia Kash Vault
                </h5>
                <h2 className="text-white remaining-funds">{`${formatAmount(
                  data.getLeaderboardDetails?.vaultData.totalAmount -
                  data.getLeaderboardDetails?.vaultData.filledAmount
                )}`}
                  <span className="d-block text-uppercase fs-6">remaining</span>
                </h2>
                <div className="doughnut-container">
                  <Doughnut
                    className="vault-circle"
                    // style={{ position: "relative", bottom: "4em", left: "9em" }}
                    data={chartData}
                  />
                </div>
              </Vault>
            </div>
            <div
              className="col-xl-12 col-md-6 col-xs-12 px-4 text-white"
              ref={listRef}
            >
              <LeaderboardList>
                {!!data.getLeaderboardDetails?.regionName ? (
                  <p className="text-uppercase fw-bold mb-0 text-center">
                    Region - {data.getLeaderboardDetails?.regionName}
                  </p>
                ) : null}
                <ul>
                  {data.getLeaderboardDetails?.userData?.map(
                    (userdata: any) => {
                      return (
                        <>
                          {!!userdata ? (
                            <li>
                              {`${userdata?.rank} ${truncate(
                                userdata?.name,
                                12,
                                divWidth!
                              )}`}
                              <span className="leaderboard-awards">
                                {formatAmount(userdata?.total)}
                              </span>
                              <span className="leaderboard-city">{`${userdata?.enrolment.city}, ${userdata?.enrolment.state}`}</span>
                            </li>
                          ) : null}
                        </>
                      )
                    }
                  )}
                </ul>

                {!!data.getLeaderboardDetails?.myCurrentLanding?.rank ? (
                  <MyCurrentStanding>
                    <h6>
                      {`${data.getLeaderboardDetails?.myCurrentLanding?.rank} My Current Standing`}{" "}
                      <span className="float-end">{`${formatAmount(
                        data.getLeaderboardDetails?.myCurrentLanding?.total
                      )}`}</span>
                    </h6>
                  </MyCurrentStanding>
                ) : null}
              </LeaderboardList>
            </div>
          </div>
        </div>
      ) : (
        <Spin className="d-flex align-items-center justify-content-center h-100"></Spin>
      )}
    </>
  )
}

export default Leaderboard