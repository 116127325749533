interface ContainerFluidProps {
  className?: string
  children: React.ReactNode
}

const ContainerFluid = ({ className, children }: ContainerFluidProps) => {
  return (
    <div className={`container-fluid py-5 px-3 px-md-5 vh-200 ${className}`}>
      {children}
    </div>
  )
}

export default ContainerFluid
