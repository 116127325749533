import { GET_ADDRESS_REPORT } from "../../graphql/queries"
import Reports from "./reports/Reports"

const checkBoxList = [
  {
    value: "firstName",
    label: "First Name",
  },
  {
    value: "lastName",
    label: "Last Name",
  },
  {
    value: "jobTitle",
    label: "Job Title",
  },
  {
    value: "previousWorkEmail",
    label: "Previous Email",
  },
  {
    value: "previousStreet1",
    label: "Previous Address Line 1",
  },
  {
    value: "previousStreet2",
    label: "Previous Address Line 2",
  },
  {
    value: "previousCity",
    label: "Previous City",
  },
  {
    value: "previousState",
    label: "Previous State",
  },
  {
    value: "previousZip",
    label: "Previous ZIP Code",
  },
  {
    value: "previousPhone",
    label: "Previous Phone",
  },
  {
    value: "updatedWorkEmail",
    label: "Updated Email",
  },
  {
    value: "updatedStreet1",
    label: "Updated Address Line 1",
  },
  {
    value: "updatedStreet2",
    label: "Updated Address Line 2",
  },
  {
    value: "updatedCity",
    label: "Updated City",
  },
  {
    value: "updatedState",
    label: "Updated State",
  },
  {
    value: "updatedZip",
    label: "Updated ZIP Code",
  },
  {
    value: "updatedPhone",
    label: "Updated Phone",
  },
  {
    value: "createdAt",
    label: "Date of Change",
  },
  {
    value: "updatedBy",
    label: "Updated By",
  },
  {
    value: "shopName",
    label: "Dealer Name",
  },
]

const ChangeAddressReport = () => {
  return (
    <Reports
      checkBoxList={checkBoxList}
      query={GET_ADDRESS_REPORT}
      title="Change Info Report"
      label="Date of Change:"
    />
  )
}

export default ChangeAddressReport
