import styled from "styled-components"

const EnrollmentHeader = styled.div`
  text-align: center;
  padding-bottom: 2em;
  img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    padding-bottom: 2em;
  }
  h4 {
    color: #999;
    text-transform: uppercase;
  }
  h1 {
    font-weight: bold;
    font-size: 3em;
    text-transform: uppercase;
  }
`

export default EnrollmentHeader
