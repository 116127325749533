import styled from "styled-components"
import KashVaultNoYearLogo from "../../assets/images/KashVaultNoYearLogo.png"
import Footer from "../../components/Layout/Footer"
import globals from "../../constants/globals"

const TextHeading = styled.div`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.secondaryColor};
`
const PhysicalCardRedeemed = () => {
  const { BRAND_LOGO_HEIGHT } = globals

  return (
    <>
      <div className="container-fluid p-4">
        <div className="row">
          <div className="col-md-10">
            <img
              src={KashVaultNoYearLogo}
              className="d-inline-block align-top"
              height={BRAND_LOGO_HEIGHT}
              alt="Logo"
            />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row py-5 bg-light justify-content-center text-center">
          <div className="pt-5 col-md-6">
            <TextHeading>
              <h2>Physical Visa Reward Card Confirmation</h2>
            </TextHeading>
            <p className="fs-6 fw-bold">
            Your mailing address has been successfully confirmed. Please allow 10-15 business days to receive your Physical Visa Reward card
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PhysicalCardRedeemed
