import { Link } from "./CustomLink"
import { AdminPaths } from "../../constants/paths"
import { AdminPermissions } from "../../constants/permissions"
import DropdownNavbar from "./DropdownNavbar"

const {
  VIEW_DEALER_UPLOADER,
  VIEW_PRODUCT_UPLOADER,
  VIEW_PARTICIPANT_UPLOADER,
  VIEW_PROMOTIONS_UPLOADER,
} = AdminPermissions
const {
  DEALER_UPLOADER_PATH,
  PRODUCT_UPLOADER_PATH,
  PARTICIPANT_UPLOADER_PATH,
  PROMOTIONS_UPLOADER_PATH,
} = AdminPaths

const items = [
  {
    label: <Link to={DEALER_UPLOADER_PATH}>Dealers</Link>,
    permission: VIEW_DEALER_UPLOADER,
    key: "1",
  },
  {
    label: <Link to={PARTICIPANT_UPLOADER_PATH}>Participants</Link>,
    permission: VIEW_PARTICIPANT_UPLOADER,
    key: "2",
  },
  {
    label: <Link to={PRODUCT_UPLOADER_PATH}>Products</Link>,
    permission: VIEW_PRODUCT_UPLOADER,
    key: "3",
  },
  {
    label: <Link to={PROMOTIONS_UPLOADER_PATH}>Promotions Uploader</Link>,
    permission: VIEW_PROMOTIONS_UPLOADER,
    key: "4",
  },
]

type UploaderNavItemsProps = {
  toggleCollapse: () => void
}

const UploaderNavItems = ({ toggleCollapse }: UploaderNavItemsProps) => {
  return (
    <DropdownNavbar
      items={items}
      label="Upload"
      toggleCollapse={toggleCollapse}
    />
  )
}

export default UploaderNavItems
