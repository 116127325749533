import { ReactNode, useState } from "react"
import styled from "styled-components"
import heroImage from "../../assets/images/HeaderImage.jpg"
import PrepaidCard from "../../assets/images/PrepaidCard.png"

type HeroSectionContainerProps = {
  vh: number
}

const HeroSectionContainer = styled.div<HeroSectionContainerProps>`
  position: relative;
  height: 100%;

  @media screen and (min-width: 1600px) {
    height: 100%;
  }

  @media screen and (min-width: 1800px) {
    height: 100%;
  }

.hero-messaging {
  position: absolute;
  bottom: 0;
  width: 100%;

  @media screen and (max-width: 1200px) {
    position: relative;
    background-color: ${({ theme }) => theme.colors.primaryColor};
  }
}

  .hero {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media screen and (max-width: 1200px) {
      height: 20vh;
    }  
  }

  .prepaidimg {
    margin-bottom: 3rem;
    transform: rotate(-10deg);
    width: 280px;

    @media screen and (max-width: 1200px) {
      width: 150px;
    }

    // @media screen and (min-width:100px) and (max-width: 400px){
    // position:absolute;
    // margin-top:9rem !important;
    // margin-left:-27rem !important;
    // }

    // @media screen and (max-width: 1800px) {
    //   width: 150px;
    //   margin-top: -10rem;
    // }
  }

    // @media screen and (min-width:100px) and (max-width: 400px){
    // .div-h2{
    //   position:absolute;
    //   margin-top:15.5rem !important;
    //  margin-left:-27rem !important;
    // }
    // .div-desc{
    //   position:absolute;
    //   margin-top:18rem !important;
    //  margin-left:-27rem !important;
    //  font-size:1.5rem;
    // }
    // .div-header{
    //    position:absolute;
    //    margin-top:19.5rem !important;
    //     margin-left:-27rem !important;
    //     }
    // }

  .anticon {
    color: #fff !important;
  }

  & > div {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .25));
    color: #fff;
    padding: 4rem;
    bottom: 0;

    @media screen and (min-width: 1600px) {
      height: 60vh;
    }

    // @media screen and (min-width: 1800px) {
    //   padding-top: 14rem;
    // }

    // @media screen and (min-width: 768px) {
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   max-width: 45%;
    // }

    // @media screen and (min-width: 992px) {
    //   max-width: 55%;
    // }

    // @media screen and (max-width: 768px) {
    //   background-color: ${({ theme }) => theme.colors.primaryColor};
    //   padding: 4rem;
    // }
  }
`



const Heading = styled.h1`
  text-transform: uppercase;
  font-weight: 800;
  font-size: 3rem;
  margin-bottom:0rem;
`

type IHeroSectionProps = {
  mutedText?: string
  heading?: string
  description?: ReactNode
  vh?: number
}

const HeroSection = ({ heading, description, vh }: IHeroSectionProps) => {
  const [showText, setShowText] = useState<boolean>(false)
  const toggleText = () => setShowText((val) => !val)
  return (
    <>
      <HeroSectionContainer vh={vh || 70}>
        <img src={heroImage} alt="Cover" onLoad={toggleText} className="hero" />
        {/* <div className="hero" onLoad={toggleText}>

        </div> */}
        {showText && (
          <div className="hero-messaging">
            <img src={PrepaidCard} width='200px' className="prepaidimg" />
            <h2 className="text-uppercase div-h2">
              Welcome to the</h2>
            <Heading className="div-desc">{heading}</Heading>

            <div className="fs-5 div-header">{description}</div>
            {/* {IS_ADMIN && <h5>Administrator Module</h5>} */}
          </div>
        )}
      </HeroSectionContainer>
    </>
  )
}

export default HeroSection
