import { useState } from "react"
import { useQuery } from "@apollo/client"
import { GET_CLAIMS } from "../../graphql/queries"
import { Input, Table } from "antd"
import { IGetClaimsResponse } from "../../types/graphqlResponse"
import useDebouncedValue from "../../hooks/useDebouncedValue"
import { myAccountClaimColumns } from "../../components/ClaimTable/ClaimColumns"
import { AdminPermissions } from "../../constants/permissions"
import useGetMyPermissions from "../../hooks/useGetMyPermissions"
import NotesModal from "../../features/ClaimNotes/Modal"
const { VIEW_CLAIM_NOTES } = AdminPermissions

const MyAccountClaimTable = () => {
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [currentPage, setCurrentPage] = useState<number>(1)
  const { permissions } = useGetMyPermissions()
  const pageSize = 10

  const { debouncedValue, debouncing } = useDebouncedValue(searchTerm)

  const { data, loading: claimsLoading } = useQuery<IGetClaimsResponse>(
    GET_CLAIMS,
    {
      variables: {
        searchTerm: debouncedValue,
        limit: pageSize,
        page: currentPage,
      },
      fetchPolicy: "no-cache",
    }
  )

  return (
    <div className="container-fluid pt-3 pb-5">
      <div className="row justify-content-center">
        <div className="col-12 border-bottom mb-3">
          <h3>Claims</h3>
        </div>
        <div className="col-12">
          <h5>Search</h5>
        </div>
        <div className="col-12 mb-3">
          <Input.Search onChange={(e) => setSearchTerm(e.target.value)} />
        </div>
      </div>
      <div className="row"></div>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <Table
            loading={claimsLoading || debouncing}
            rowKey="id"
            columns={myAccountClaimColumns}
            dataSource={data?.listClaimsWithPagination.claims}
            scroll={{ x: "max-content" }}
            pagination={{
              pageSize,
              total: data?.listClaimsWithPagination.totalDocs,
              current: currentPage,
              onChange(page) {
                setCurrentPage(page)
              },
              showSizeChanger: false,
            }}
          />
          {permissions.includes(VIEW_CLAIM_NOTES) && <NotesModal />}
        </div>
      </div>
    </div>
  )
}

export default MyAccountClaimTable
