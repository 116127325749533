import { Link } from "./CustomLink"
import { AdminPaths } from "../../constants/paths"
import { AdminPermissions } from "../../constants/permissions"
import DownloadCurrentIncentives from "../CurrentIncentives/DownloadCurrentIncentives"
import DropdownNavbar from "./DropdownNavbar"

const {
  VIEW_ENROLLMENT_REPORT,
  VIEW_CLAIM_REPORT,
  VIEW_CHANGE_ADDRESS_REPORT,
  VIEW_CURRENT_INCENTIVES_REPORT,
} = AdminPermissions

const {
  CLAIM_REPORT_PATH,
  ENROLLMENT_REPORT_PATH,
  CHANGE_ADDRESS_REPORT_PATH,
} = AdminPaths

const items = [
  {
    label: <Link to={CLAIM_REPORT_PATH}>Claim Report</Link>,
    permission: VIEW_CLAIM_REPORT,
    key: "1",
  },
  {
    label: <Link to={ENROLLMENT_REPORT_PATH}>Enrollment Report</Link>,
    permission: VIEW_ENROLLMENT_REPORT,
    key: "2",
  },
  {
    label: <Link to={CHANGE_ADDRESS_REPORT_PATH}>Change Info Report</Link>,
    permission: VIEW_CHANGE_ADDRESS_REPORT,
    key: "3",
  },
  {
    label: <DownloadCurrentIncentives />,
    permission: VIEW_CURRENT_INCENTIVES_REPORT,
    key: "4",
  },
]

type ReportsNavItemsProps = {
  toggleCollapse: () => void
}

const ReportsNavItems = ({ toggleCollapse }: ReportsNavItemsProps) => {
  return (
    <DropdownNavbar
      items={items}
      label="Reports"
      toggleCollapse={toggleCollapse}
    />
  )
}

export default ReportsNavItems
