import { Form, Input } from "antd"
import { validateLeadingTrailingSpaces } from "../../utils/formUtils"

const Name = () => {
  return (
    <Form.Item
      label="Name"
      name="name"
      rules={[
        {
          required: true,
          message: "Please enter your name",
        },
        validateLeadingTrailingSpaces()
      ]}
    >
      <Input />
    </Form.Item>
  )
}

export default Name
