import FundingHistory from "../../components/FundingHistory/FundingHistory"

const MyAccountFundingHistoryTable = () => {
  return (
    <div className="container-fluid pt-3 pb-5">
      <div className="row justify-content-center">
        <div className="col-12 border-bottom mb-3">
          <h3>Funding History</h3>
        </div>
      </div>
      <div className="row"></div>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <FundingHistory />
        </div>
      </div>
    </div>
  )
}

export default MyAccountFundingHistoryTable
