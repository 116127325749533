import { InfoCircleOutlined } from "@ant-design/icons"
import { Tag } from "antd"

import { useAppDispatch } from "../../redux/hooks"
import { setAiStatusId } from "../../redux/ui/uiSlice"
import { IClaim } from "../../types/graphqlResponse"

function AIStatusComponent({ record }: { record: IClaim }) {
  const dispatch = useAppDispatch()
  return (
    <div
      onClick={() => {
        dispatch(setAiStatusId(record.id))
      }}
    >
      <Tag color={record.AIStatus === "AI_APPROVED" ? "green" : "red"}>
        {record.AIStatus}
      </Tag>
      <InfoCircleOutlined />
    </div>
  )
}

export { AIStatusComponent }
