import { useMutation } from "@apollo/client"
import { Popconfirm, Typography } from "antd"
import { ColumnsType } from "antd/es/table"

import { useAppDispatch } from "../../redux/hooks"
import { setSelectedNote } from "../../redux/ui/uiSlice"
import { formatDateWithTime } from "../../utils/date"
import DownloadPopover from "../../components/DownloadPopover"
import { DELETE_NOTE } from "./graphql"
import { INote } from "./types"

const notesColumns: ColumnsType<INote> = [
  { title: "Creator", dataIndex: "createdByName" },
  {
    title: "Note",
    dataIndex: "note",
    render: (value) => (
      <Typography.Text ellipsis={true} style={{ width: "200px" }}>
        {value}
      </Typography.Text>
    ),
  },
  {
    title: "Document",
    render: (_, { noteDocumentUrl }) => {
      return (
        noteDocumentUrl?.length > 0 && (
          <DownloadPopover
            urls={noteDocumentUrl}
            label="Download"
            title="Download"
          />
        )
      )
    },
  },
  {
    title: "Creation Date",
    dataIndex: "createdAt",
    render: (value) => formatDateWithTime(value),
  },
  {
    title: "Updation Date",
    dataIndex: "updatedAt",
    render: (value) => formatDateWithTime(value),
  },
  {
    title: "Action",
    render: (_, record) =>
      record.isNoteByLoggedInUser && (
        <>
          <EditNoteButton note={record} />
          <DeleteNoteButton note={record} />
        </>
      ),
  },
]

/**
 * Opens up the edit modal by setting redux state with
 * details of the selected note
 */
const EditNoteButton = ({ note }: { note: INote }) => {
  const dispatch = useAppDispatch()

  const onClick = () => {
    dispatch(setSelectedNote(note))
  }

  return (
    <button
      type="button"
      className="border-0 bg-transparent"
      title="Edit Note"
      onClick={onClick}
    >
      <span className="custom-link">Edit</span>
    </button>
  )
}

/**
 * Deletes the note after confirmation with `Popconfirm`
 * and refetches notes
 */
const DeleteNoteButton = ({ note }: { note: INote }) => {
  const [deleteNote] = useMutation(DELETE_NOTE)

  const onConfirm = () => {
    deleteNote({
      variables: {
        noteId: note._id,
      },
      refetchQueries: ["NotesByClaimId"],
    })
  }

  return (
    <Popconfirm
      onConfirm={onConfirm}
      title="Are you sure you want to delete this note?"
      okText="Delete"
      okButtonProps={{ danger: true }}
    >
      <button
        type="button"
        className="border-0 bg-transparent"
        title="Delete Note"
      >
        <span className="custom-link">Delete</span>
      </button>
    </Popconfirm>
  )
}

export default notesColumns