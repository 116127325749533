import { Form, Alert, Divider, Checkbox, UploadFile } from "antd"
import { ReactNode, useState } from "react"
import { useMutation, useQuery } from "@apollo/client/react"
import { useNavigate, useSearchParams } from "react-router-dom"

import {
  CREATE_ENROLLMENT,
  VALIDATE_ADDRESS,
  VALIDATE_ENROLLMENT_FORM,
} from "../../graphql/mutations"
import Button, { SecondaryButton } from "../../components/styled/Button.styled"
import FileUpload from "../../components/FileUpload"
import globals from "../../constants/globals"
import EnrollmentSuccess from "../../components/EnrollmentForm/EnrollmentSuccess"
import Spinner from "../../components/Spinner"
import Footer from "../../components/Layout/Footer"
import Header from "../../components/Layout/Header"
import LegalFirstName from "../../components/EnrollmentForm/LegalFirstName"
import LegalLastName from "../../components/EnrollmentForm/LegalLastName"
import GoesByName from "../../components/EnrollmentForm/GoesByName"
import JobTitle from "../../components/EnrollmentForm/JobTitle"
import WorkEmail from "../../components/EnrollmentForm/WorkEmail"
import PhoneNumber from "../../components/EnrollmentForm/PhoneNumber"
import Address2 from "../../components/EnrollmentForm/Address2"
import City from "../../components/EnrollmentForm/City"
import State from "../../components/EnrollmentForm/State"
import ZIP from "../../components/EnrollmentForm/ZIP"
import DealerZIP from "../../components/EnrollmentForm/DealerZIP"
import DealerState from "../../components/EnrollmentForm/DealerState"
import DealerCity from "../../components/EnrollmentForm/DealerCity"
import DealerNameReadOnly from "../../components/EnrollmentForm/DealerNameReadOnly"
import DealerNameNew from "../../components/EnrollmentForm/DealerNameNew"
import KUID from "../../components/EnrollmentForm/KUID"
import AddressAutocomplete from "../../components/EnrollmentForm/AddressAutocomplete"
import trimFormValues from "../../utils/trimFormValues"
import { ISigninResponse } from "../../types/graphqlResponse"
import { setAuthToken, setSessionId } from "../../utils/auth"
import { useAppDispatch } from "../../redux/hooks"
import { login, setTokenTimeout } from "../../redux/auth/authSlice"
import EnrollmentHeader from "../../components/EnrollmentForm/EnrollmentHeader"
import heroImage from "../../assets/images/HeaderImage.jpg"
import { GET_PRE_ENROLLMENT_DATA } from "../../graphql/queries"
import { IGetPreEnrollmentDataResponse } from "../../components/EnrollmentForm/types"
import RESET_ALERT from "../../constants/resetAlert"
import { useAuthStatus } from "../../hooks/useAuthStatus"
import { ConsumerPaths } from "../../constants/paths"

const { PROGRAM_NAME } = globals
const { ENROLLMENT_FORM_PATH } = ConsumerPaths

const EnrollmentForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  /** Search params for 'email' should always be there */
  const [searchParams] = useSearchParams()

  // Tokens from URL
  const token = searchParams.get("token")
  const sessionId = searchParams.get("session")

  const [alert, setAlert] = useState(RESET_ALERT)

  const [checked, setChecked] = useState(false)
  const [isNewDealer, setIsNewDealer] = useState(false)
  const [readOnly, setReadOnly] = useState<boolean>(false)
  const [phoneNumberVerification, setPhoneNumberVerification] =
    useState<boolean>(false)
  const [checkPhoneVeification, setCheckPhoneVeification] =
    useState<boolean>(false)
  const [textCodeDisabled, setTextCodeDisabled] = useState<boolean>(true)
  const [blockSubmit, setBlockSubmit] = useState<boolean>(false)
  const [verified, setVerified] = useState<boolean>(false)
  const [isDealerAddressValidated, setIsDealerAddressValidated] = useState(true)
  const [isPersonalAddressValidated, setIsPersonalAddressValidated] =
    useState(true)

  const [form] = Form.useForm()

  const { data: preEnrollmentData } = useQuery<IGetPreEnrollmentDataResponse>(
    GET_PRE_ENROLLMENT_DATA,
    {
      variables: { email: token },
      skip: !token,
      onError(error) {
        setAlert({
          type: "error",
          message: error.message,
        })
      },
    }
  )

  // Mutation for creating an enrollment
  const [
    enrollment,
    { loading: creatingEnrollment, error: errorEnrollment, reset },
  ] = useMutation(CREATE_ENROLLMENT)
  // Mutation for validating an enrollment
  const [
    validateEnrollment,
    { reset: validateReset, error: validateError, loading: validateLoading },
  ] = useMutation(VALIDATE_ENROLLMENT_FORM)
  // Mutation for validating addresses
  const [
    validateAddress,
    { reset: validateReset2, error: validateError2, loading: validateLoading2 },
  ] = useMutation<{ validateAddress: "true" | "false" }>(VALIDATE_ADDRESS)

  const { loggedIn } = useAuthStatus()

  // Form submit handler
  const onFinish = (values: any) => {
    setTextCodeDisabled(true)
    reset()
    validateReset()
    validateReset2()
    if (blockSubmit) {
      return
    }
    if (!readOnly) {
      return validateAndToggleReadOnly(values)
    }
    // Trim whitespace from values
    const trimmedValues = trimFormValues(values)

    const forApi = {
      enrolment: {
        ...trimmedValues,
        // Since the 'dealerId' field is now readOnly,
        // we are using the name instead of the 'id'
        dealerId: preEnrollmentData?.participantByEmailId.dealer._id,
        smsOptIn: checked,
        document: values.document?.fileList.map(
          (file: UploadFile) => file.originFileObj
        ),
      },
    }
    enrollment({
      variables: forApi,
      onError: toggleReadOnly,
      // Sign in user after successful enrollment
      onCompleted: (data: { createEnrolment: ISigninResponse["signIn"] }) => {
        setAuthToken({
          token: data.createEnrolment.token,
          refresh_token: data.createEnrolment.refreshToken,
        })
        if (sessionId) {
          setSessionId(sessionId)
        }
        dispatch(setTokenTimeout(data.createEnrolment.tokenTime))
        dispatch(login())
        navigate(ENROLLMENT_FORM_PATH)
      },
    })
  }

  const toggleReadOnly = (): void => setReadOnly((val) => !val)
  // Applicable for New Dealer Flow. Not being used.
  // eslint-disable-next-line
  const toggleExistingNewDealer = () => {
    setIsNewDealer((val) => !val)
    form.resetFields([
      "dealerId",
      "dealerStreetAddress",
      "dealerState",
      "dealerCity",
      "dealerZip",
      "dealerShopName",
    ])
  }

  const validateAndToggleReadOnly = (values: any) => {
    const dealerAddressVariables = {
      dealerStreetAddress: form.getFieldValue("dealerStreetAddress"),
      dealerCity: form.getFieldValue("dealerCity"),
      dealerState: form.getFieldValue("dealerState"),
      dealerZip: form.getFieldValue("dealerZip"),
      secondary: form.getFieldValue("secondary"),
    }

    const personalAddressVariables = {
      dealerStreetAddress: form.getFieldValue("street1"),
      dealerCity: form.getFieldValue("city"),
      dealerState: form.getFieldValue("state"),
      dealerZip: form.getFieldValue("zip"),
      secondary: form.getFieldValue("street2"),
    }

    // Validate Personal Address
    validateAddress({
      variables: personalAddressVariables,
      onCompleted: (data) => {
        setIsPersonalAddressValidated(data.validateAddress === "true")
        // Validate Dealer Address if New Dealer
        if (isNewDealer && data.validateAddress === "true") {
          validateAddress({
            variables: dealerAddressVariables,
            onCompleted: (data) => {
              setIsDealerAddressValidated(data.validateAddress === "true")
              // Validate Enrollment, basically for duplicate email
              if (data.validateAddress === "true") {
                validateEnrollment({
                  variables: {
                    workEmail: values.workEmail,
                    dealerStreetAddress: values.dealerStreetAddress,
                    dealerZip: values.dealerZip,
                  },
                  onCompleted: () => {
                    window.scrollTo(0, 500)
                    toggleReadOnly()
                  },
                })
              }
            },
          })
        } else {
          // Validate Enrollment, same as nested one above
          if (data.validateAddress === "true") {
            validateEnrollment({
              variables: { workEmail: values.workEmail },
              onCompleted: () => {
                window.scrollTo(0, 500)
                toggleReadOnly()
              },
            })
          }
        }
      },
    })
  }

  if (loggedIn) {
    return (
      <>
        <Header />
        <div className="vh-150">
          <EnrollmentSuccess />
        </div>
        <Footer />
      </>
    )
  }

  if (preEnrollmentData) {
    const {
      participant: {
        firstName,
        lastName,
        incentiveJobCategory: jobTitle,
        workEmail,
        kuid,
      },
      dealer: {
        shopName: dealerId,
        city: dealerCity,
        state: dealerState,
        streetAddress: dealerStreetAddress,
        zip: dealerZip,
      },
    } = preEnrollmentData.participantByEmailId

    const initialValues = {
      firstName,
      lastName,
      jobTitle,
      workEmail,
      kuid,
      dealerId,
      dealerStreetAddress,
      dealerCity,
      dealerState,
      dealerZip,
    }

    return (
      <EnrollmentFormContainer>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-11 border p-md-5 p-3">
            {readOnly && <ConfirmationPageMessage />}
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
              disabled={readOnly}
              requiredMark={false}
              initialValues={initialValues}
            >
              <h5>1. Personal Contact Information</h5>

              <div className="row">
                {[
                  <LegalFirstName disabled />,
                  <LegalLastName disabled />,
                  <GoesByName />,
                  <JobTitle disabled />,
                  <WorkEmail disabled />,
                  <PhoneNumber
                    phoneVerification={phoneNumberVerification}
                    checkPhoneVeification={checkPhoneVeification}
                    setTextCodeDisabled={(state) => {
                      setTextCodeDisabled(state)
                      setPhoneNumberVerification(false)
                      !!state
                        ? form.setFields([{ name: "phone", errors: [] }])
                        : form.setFields([{ name: "phone", errors: [] }])
                    }}
                    setVerified={(state) => {
                      setVerified(state)
                    }}
                  />,
                  <KUID disabled />,
                  <></>,
                  // For Address 1
                  <AddressAutocomplete
                    form={form}
                    name="street1"
                    label="Address 1"
                    formItemNamesToUpdate={["street2", "city", "state", "zip"]}
                    requiredMessage="Please enter an address"
                    disabled={readOnly}
                  />,
                  <Address2 />,
                  <City />,
                ].map((item, index) => (
                  <div className="col-sm-6" key={index}>
                    {item}
                  </div>
                ))}

                <div className="col-sm-6">
                  <div className="row">
                    <div className="col">
                      <State />
                    </div>
                    <div className="col">
                      <ZIP />
                    </div>
                  </div>
                </div>
              </div>

              <Divider />

              <h5>2. Dealer Information</h5>

              <div className="row">
                {/* New Dealer Flow not used for now */}
                {/* <Checkbox
                      onChange={toggleExistingNewDealer}
                      checked={isNewDealer}
                      className="my-3"
                    >
                      New Dealer
                    </Checkbox> */}

                {isNewDealer ? (
                  <div className="col-12">
                    <DealerNameNew />
                  </div>
                ) : (
                  <div className="col-12">
                    {/* Commenting because dealer will be read-only now */}
                    {/* <DealerNameExisting form={form} /> */}
                    <DealerNameReadOnly disabled={!isNewDealer || readOnly} />
                  </div>
                )}

                <div className="col-12">
                  <AddressAutocomplete
                    form={form}
                    name="dealerStreetAddress"
                    label="Dealer Address"
                    formItemNamesToUpdate={[
                      "none",
                      "dealerCity",
                      "dealerState",
                      "dealerZip",
                    ]}
                    requiredMessage="Please enter a dealer address"
                    disabled={!isNewDealer || readOnly}
                  />
                </div>

                <div className="col-sm-6">
                  <DealerCity disabled={!isNewDealer || readOnly} />
                </div>

                <div className="col-sm-6">
                  <div className="row">
                    <div className="col">
                      <DealerState disabled={!isNewDealer || readOnly} />
                    </div>
                    <div className="col">
                      <DealerZIP disabled={!isNewDealer || readOnly} />
                    </div>
                  </div>
                </div>
              </div>

              <FileUpload setBlockSubmit={setBlockSubmit} maxFileCount={10} />

              <Divider />

              <h5>3. Mobile Phone</h5>

              <Checkbox
                onChange={(e) => {
                  setChecked(e.target.checked)
                  if (verified === false) {
                    setCheckPhoneVeification(e.target.checked)
                  }
                  e.target.checked === true &&
                  textCodeDisabled === false &&
                  verified === false
                    ? form.setFields([
                        {
                          name: "phone",
                          errors: [
                            "Click the “Text Code” button to receive the 4-digit code to verify your phone number",
                          ],
                        },
                      ])
                    : form.setFields([{ name: "phone", errors: [] }])
                }}
                checked={checked}
                disabled={textCodeDisabled}
              >
                I want to receive SMS messages regarding this program
              </Checkbox>

              <div className="col-md-2 my-3">
                <Button
                  type="button"
                  onClick={() => {
                    setPhoneNumberVerification(true)
                  }}
                  disabled={textCodeDisabled}
                >
                  Text Code
                </Button>
              </div>

              <Divider />
            </Form>

            {/* Spinner */}
            <div className="row">
              {(creatingEnrollment || validateLoading || validateLoading2) && (
                <Spinner />
              )}
            </div>

            {/* Info Message For Address Validation */}
            {!isDealerAddressValidated && (
              <div className="col-12 mb-3">
                <Alert
                  type="error"
                  message="Please enter the correct dealer address"
                />
              </div>
            )}
            {!isPersonalAddressValidated && (
              <div className="col-12 mb-3">
                <Alert
                  type="error"
                  message="Please enter correct personal address"
                />
              </div>
            )}

            {/* Error Messages */}
            {errorEnrollment && (
              <div className="col-12 mb-3">
                <Alert type="error" message={errorEnrollment.message} />
              </div>
            )}
            {validateError && (
              <div className="col-12 mb-3">
                <Alert type="error" message={validateError.message} />
              </div>
            )}
            {validateError2 && (
              <div className="col-12 mb-3">
                <Alert type="error" message={validateError2.message} />
              </div>
            )}
            <div className="row justify-content-center mt-3">
              {readOnly && (
                <div className="col-md-6 mb-3 mb-md-3">
                  <SecondaryButton
                    type="button"
                    onClick={() => {
                      toggleReadOnly()
                      setTextCodeDisabled(false)
                    }}
                    disabled={
                      creatingEnrollment || validateLoading || validateLoading2
                    }
                  >
                    Go Back
                  </SecondaryButton>
                </div>
              )}
              <div className="col-md-6">
                <Button
                  type="submit"
                  onClick={form.submit}
                  disabled={
                    creatingEnrollment || validateLoading || validateLoading2
                  }
                >
                  {readOnly ? "Enroll Now" : "Continue"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </EnrollmentFormContainer>
    )
  }

  return (
    <>
      <Header forEnrollmentForm />
      <div className="vh-200">
        <div className="container-fluid pb-4">
          <EnrollmentHeader>
            <img src={heroImage} alt="Kia Kash Vault 2023" />
          </EnrollmentHeader>
          {!token ? (
            <Alert type="error" message="Incorrect URL" />
          ) : alert.message ? (
            <Alert
              type={alert.type}
              message={<div className="text-center">{alert.message}</div>}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <Footer />
    </>
  )
}

const EnrollmentFormContainer = ({ children }: { children: ReactNode }) => (
  <>
    <Header forEnrollmentForm />
    <div className="vh-250">
      <div className="container-fluid pb-4">
        <EnrollmentHeader>
          <img src={heroImage} alt="Kia Kash Vault 2023" />
          <div className="row justify-content-center">
            <div className="col-8">
              <h4>{PROGRAM_NAME}</h4>
              <h1>Enroll Now</h1>
              <p>
                The Kia Kash Vault is here! You can EARN REWARDS every time you
                submit a Sales Claim for eligible genuine Kia Dealer Installed
                Options and Accessories. It's a great way to give Kia owners a
                great experience and earn rewards in the process.
              </p>
              <p>
                BUT HURRY! The Kia Kash Vault won't last forever and when it's
                gone, it's gone.
              </p>
            </div>
          </div>
        </EnrollmentHeader>
        {/* Child Component */}
        {children}
      </div>
    </div>
    <Footer />
  </>
)

const ConfirmationPageMessage = () => (
  <Alert
    type="info"
    message={
      <>
        <strong>CONFIRMATION PAGE:</strong> Please confirm your information is
        correct, then click on the Enroll Now button complete your enrollment.
      </>
    }
    className="mb-3"
  />
)

export default EnrollmentForm
